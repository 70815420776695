<template>
  <div>
    <ly-dialog
      v-model="dialogVisible"
      :title="loadingTitle"
      width="640px"
      :before-close="handleClose"
    >
      <el-form
        :inline="false"
        :model="formData"
        :rules="rules"
        ref="rulesForm"
        label-position="right"
        label-width="auto"
      >
        <el-form-item label="产品名称：" prop="name" required>
          <el-input placeholder="请输入" v-model="formData.name"></el-input>
        </el-form-item>
        <!-- <el-form-item label="产品类型：" prop="category" required>
          <el-select
            v-model="formData.category"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, key, index) in categoryList"
              :key="index"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="产品状态：" prop="status" required>
          <el-select
            v-model="formData.status"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="(item,index) in statusList"
              :key="index"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品描述：">
         <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="formData.description"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="题目数量：" prop="item_num" required>
        <el-input-number v-model="formData.item_num"></el-input-number>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
        <el-button type="primary" @click="submitData" :loading="loadingSave"
          >确定</el-button
        >
      </template>
    </ly-dialog>
  </div>
</template>

<script>
import { productspost, productsputId } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import XEUtils from "xe-utils";
import { deepClone } from "@/utils/util";
export default {
  props:['categoryList','statusList'],
  components: { LyDialog },
  emits: ['refreshData'],
  name: "addStudentsEvaluate",
  data() {
    return {
      dateList: [],

      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
      formData: {
        name: '',
        status: '0',
        // category: '',
        item_num:0
      },
      rules: {
        status:[ { required: true, message: '请输入产品状态', trigger: 'change' }],
        category:[   { required: true, message: '请输入产品类型', trigger: 'change' }],
        item_num:[  { required: true, message: '请输入题目数量', trigger: 'blur' }],
        name: [
          { required: true, message: '请输入产品名称', trigger: 'blur' }
        ],
        dept_id: [
          { required: true, message: '请授权机构', trigger: 'blur' }
        ]
      },
      rolelist: [],
      options: [],
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
      this.loadingSave = false
      this.formData = {
        name: '',
        status: '0',
        // category: '',
        item_num:0
      }
    },
    addAdminFn(item, flag) {
      this.loadingTitle = flag
      this.dialogVisible = true
      // console.log(item,'item----')
      // if(item && item.dept) {
      //     item.dept = item.dept.split(" ")
      // }
      if (item) {
        this.formData = deepClone(item)
      }

      this.formData.role = item ? item.role : []
    },
    submitData() {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = {
            ...this.formData
          }
          console.log(this.formData.id)
          if (this.formData.id) {
            productsputId(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            productspost(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
    },



  }
}
</script>

