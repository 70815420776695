<template>
  <div :class="{ 'ly-is-full': isFull }">
    <div class="tableSelect" ref="tableSelect">
      <el-form :inline="true" :model="formInline" label-position="left">
        <el-form-item label="部门名称：">
          <el-cascader
            v-model="optionschool"
            style="width: 260px"
            :options="options"
            :props="props"
            collapse-tags
            clearable
            @change="handlecascader"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="报告名称：">
          <el-input
            size="default"
            v-model.trim="formInline.report_name"
            maxlength="60"
            clearable
            placeholder="报告名称"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="报告状态：">
          <!-- statusList -->
          <el-select
            size="default"
            v-model="formInline.status"
            placeholder="请选择"
            clearable
            style="width: 200px"
          >
            <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户名称：">
          <el-input
            size="default"
            v-model.trim="formInline.name"
            maxlength="60"
            clearable
            placeholder="学生姓名"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户账号：">
          <el-input
            size="default"
            v-model.trim="formInline.username"
            maxlength="60"
            clearable
            placeholder="用户账号"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label=""
          ><el-button @click="search" type="primary" icon="Search"
            >搜索</el-button
          ></el-form-item
        >
        <el-form-item label=""
          ><el-button @click="handleEdit('', 'reset')" icon="Refresh"
            >重置</el-button
          ></el-form-item
        >
      </el-form>
      <!-- <div class="appdownload">
          <el-button  @click="search" type="primary" icon="Search" v-show="isShowBtn('adminManage','管理员管理','Search')">搜索</el-button> 
          <el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button>
          </div> -->
    </div>
    <!-- <div class="tableSelect">
      <el-button @click="addAdmin" type="primary" icon="Search">新增</el-button>
      <el-button @click="handleDelteAll()" icon="Refresh">删除</el-button>
    </div> -->
    <div class="table">
      <el-table
        :height="'calc(' + tableHeight + 'px)'"
        border
        :data="tableData"
        ref="tableref"
        v-loading="loadingPage"
        style="width: 100%"
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <!--<el-table-column min-width="150" prop="id" label="管理员编号" show-overflow-tooltip></el-table-column>-->

        <el-table-column
          min-width="100"
          prop="name"
          label="用户名称"
        ></el-table-column>
        <el-table-column min-width="100" prop="username" label="用户账号">
        </el-table-column>
        <!-- <el-table-column min-width="100" prop="begin_date" label="学号">
        </el-table-column> -->
        <el-table-column min-width="100" prop="user_dept" label="所属部门">
        </el-table-column>
        <el-table-column min-width="100" prop="report_name" label="报告名称">
        </el-table-column>

        <el-table-column min-width="100" prop="product" label="测评方案">
        </el-table-column>
        <el-table-column min-width="100" prop="status_label" label="报告状态">
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="180">
          <template #header>
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div>操作</div>
              <div @click="setFull">
                <el-tooltip content="全屏" placement="bottom">
                  <el-icon><full-screen /></el-icon>
                </el-tooltip>
              </div>
            </div>
          </template>
          <template #default="scope">
            <el-button
              type="text"
              :disabled="scope.row.status !== '1'"
              class="table-operate-btn"
              @click="handleEdit(scope.row, 'look')"
              >查看报告</el-button
            >
            <el-button
              type="text"
              :disabled="scope.row.status !== '1'"
              class="table-operate-btn"
              @click="handleEdit(scope.row, 'dowmload')"
              >下载报告</el-button
            >

            <!-- <span
              class="table-operate-btn"
    
              >通知家长</span
            > -->
            <!--   <span
              class="table-operate-btn"
              @click="handleEdit(scope.row, 'status')"
              v-if="scope.row.status !== '3'"
              >完成报告</span
            > -->
            <!-- <span
              class="table-operate-btn"
              @click="handleEdit(scope.row, 'delete')"
              v-show="isShowBtn('adminManage', '管理员管理', 'Delete')"
              >通知家长</span
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      v-bind:child-msg="pageparm"
      @callFather="callFather"
    ></Pagination>
    <!-- <addStudentsEvaluate
      :userlist="userlist"
      :productsgetlist="productsgetlist"
      ref="addStudentsEvaluateflag"
      @refreshData="getData"
    ></addStudentsEvaluate> -->
  </div>
</template>
<script>
// import addStudentsEvaluate from "./components/addStudentsEvaluate";
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight, setStore } from "@/utils/util";
import { useMutitabsStore } from "@/store/mutitabs";
import { iframePdf, sreportPdf, reportAbbrget, sreportget, srepordelete, productsget, UsersUsers, sreportput, options, sreportpost, deptSchoolPeo } from '@/api/api'
export default {
  components: {
    Pagination
  },
  name: 'personalEvaluation',
  setup() {
    const mutitabsstore = useMutitabsStore()
    return { mutitabsstore }
  },
  data() {
    return {
      optionschool: [],
      dateList: [],
      props: { multiple: true, value: 'id', label: 'name' },
      options: [],
      objArrDetle: [],
      userlist: [],// pingce
      isFull: false,
      visible: false,
      tableHeight: 500,
      loadingPage: false,
      formInline: {
        report_name: '',
        status: '',
        user: '',
        username: '',
        dept_id: '',
        page: 1,
        limit: 10,
      },
      pageparm: {
        page: 1,
        limit: 10,
        total: 0
      },
      statusList: [],
      timers: [],
      tableData: []
    }
  },
  methods: {
    handlecascader(e) {
      let arr = []
      e.forEach(i => {
        arr.push(i[i.length - 1])
      })
      this.formInline.dept_id = arr.join(',')
    },
    handlerPicker(e) {
      // console.log(e)
      if (e) {
        this.formInline.begin_date = e[0]
        this.formInline.end_date = e[1]
      } else {
        this.formInline.begin_date = ''
        this.formInline.end_date = ''
      }
    },
    getSchoolData() {
      deptSchoolPeo({
        limit: 999, page: 1
      }).then(res => {
        // console.log(res.data)
        this.options = res.data.data
      })
    },
    handleDelteAll() {
      if (this.objArrDetle.length) {
        let vm = this
        vm.$confirm('您确定要删除选中的内容么？', {
          closeOnClickModal: false
        }).then(res => {
          srepordelete({ id: this.objArrDetle.join(',') }).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.search()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      }

    },
    handleSelectionChange(e) {
      this.objArrDetle = []
      e.forEach(i => {
        this.objArrDetle.push(i.id)
      })
      // // console.log(e)
    },
    getoptions() {
      options({ parent__isnull: true, title: '报告状态' }).then(res => {
        this.statusList = res.data.data[0].children
      })

    },
    // 表格序列号
    getIndex($index) {
      // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
      return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
    },
    setFull() {
      this.isFull = !this.isFull
      window.dispatchEvent(new Event('resize'))
    },
    addAdmin() {
      this.$refs.addStudentsEvaluateflag.addAdminFn(null, '新增')
    },
    handleEdit(row, flag) {
      if (flag == 'look') {
        reportAbbrget(row.report_abbr).then(res => {
          setStore('fileUrl', res.report_addr)
          this.$router.push({ path: '/filePreview' })
        })
      }
      else if (flag == 'delete') {
        let vm = this
        vm.$confirm('您确定要删除选中的内容么？', {
          closeOnClickModal: false
        }).then(res => {
          srepordelete(row).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.search()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      } else if (flag == 'status') {
        // let vm = this
        // vm.$confirm('您确定要完成选中的内容么？', {
        //   closeOnClickModal: false
        // }).then(res => {
        //   row['status'] = '3'
        //   singleStput(row).then(res => {
        //     if (res.code == 2000) {
        //       vm.$message.success(res.msg)
        //       vm.search()
        //     } else {
        //       vm.$message.warning(res.msg)
        //     }
        //   })
        // }).catch(() => {

        // })
      }
      else if (flag == "reset") {
        this.optionschool = []
        this.formInline = {
          report_name: '',
          status: '',
          user: '',
          dept_id: '',
          page: 1,
          username: '',
          limit: 10,
        }
        this.pageparm = {
          page: 1,
          limit: 10,
          total: 0
        }
        this.dateList = []
        this.getData()
      } else if (flag == 'dowmload') {
        reportAbbrget(row.report_abbr).then(res => {

          window.open(res.report_addr, '_blank');

        })
      }
    },

    callFather(parm) {
      this.formInline.page = parm.page
      this.formInline.limit = parm.limit
      this.getData()
    },
    search() {
      this.formInline.page = 1
      this.formInline.limit = 10
      this.getData()
    },
    // async getDataUsersUsers() {

    //   UsersUsers({ page: 1, limit: 999 }).then(res => {
    //     this.loadingPage = false
    //     if (res.code == 2000) {
    //       this.userlist = res.data.data
    //     }
    //   })
    // },
    async getDataproductsget() {
      productsget({ page: 1, limit: 999 }).then(res => {
        if (res.code == 2000) {
          this.productsgetlist = res.data.data
        }
      })
    },
    //获取列表
    async getData() {
      this.loadingPage = true
      sreportget(this.formInline).then(res => {
        this.loadingPage = false
        if (res.code == 2000) {
          this.tableData = res.data.data

          this.pageparm.page = res.data.page;
          this.pageparm.limit = res.data.limit;
          this.pageparm.total = res.data.total;
        }
      })
    },

    timeChange(val) {
      if (val) {
        this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    // 计算搜索栏的高度
    listenResize() {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight() {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight + 50 : 0
      tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight = getTableHeight(tabSelectHeight)
    }
  },
  created() {
    this.getData()
    // this.getDataUsersUsers()
    // this.getDataproductsget()
    this.getSchoolData()
    this.getoptions()
  },
  mounted() {
    // 监听页面宽度变化搜索框的高度
    window.addEventListener('resize', this.listenResize);
    this.$nextTick(() => {
      this.getTheTableHeight()
    })
  },
  unmounted() {
    // 页面销毁，去掉监听事件
    window.removeEventListener("resize", this.listenResize);
  },
}
</script>
<style>
.appdownload {
  display: flex;
  justify-content: flex-end;
}
</style>
