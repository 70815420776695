<template>
  <div :class="{ 'ly-is-full': isFull }">
    <div class="tableSelect" ref="tableSelect">
      <el-form :inline="true" :model="formInline" label-position="left">
        <el-form-item label="部门名称：">
           <el-cascader
            v-model="optionschool"
            style="width: 260px"
            :options="options"
            :props="props"
            collapse-tags
            clearable
            @change="handlecascader"
          ></el-cascader>
        </el-form-item>

        <el-form-item label="用户名称：">
          <el-input
            size="default"
            v-model.trim="formInline.name"
            maxlength="60"
            clearable
            placeholder="用户名称"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="学号：">
          <el-input
            size="default"
            v-model.trim="formInline.evaluate_status"
            maxlength="60"
            clearable
            placeholder="任务名称"
            @change="search"
            style="width: 200px"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="手机号码：">
          <el-input
            size="default"
            v-model.trim="formInline.mobile"
            maxlength="60"
            clearable
            placeholder="手机号码"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="预警级别：">
          <el-select
            size="default"
            v-model="formInline.warning_level"
            placeholder="请选择预警级别"
            clearable
            style="width: 200px"
          >
            <el-option
              v-for="item in warning_levelList"
              :key="item.key"
         :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
       <el-form-item label="评估状态：">
          <el-select
            size="default"
            v-model="formInline.evaluate_status"
            placeholder="请选择评估状态"
            clearable
            style="width: 200px"
          >
            <el-option
              v-for="item in evaluate_statusList"
              :key="item.key"
            :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=""
          ><el-button
            @click="search"
            type="primary"
            icon="Search"
            >搜索</el-button
          ></el-form-item
        >
        <el-form-item label=""
          ><el-button @click="handleEdit('', 'reset')" icon="Refresh"
            >重置</el-button
          ></el-form-item
        >
      </el-form>
      <!-- <div class="appdownload">
          <el-button  @click="search" type="primary" icon="Search" v-show="isShowBtn('adminManage','管理员管理','Search')">搜索</el-button> 
          <el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button>
          </div> -->
    </div>
    <div class="tableSelect">
      <el-button @click="addAdmin" type="primary" icon="Plus">新增</el-button>
      <el-button @click="handleDelteAll()" icon="Delete">删除</el-button>
    </div>
    <div class="table">
      <el-table
        :height="'calc(' + tableHeight + 'px)'"
        border
        :data="tableData"
        ref="tableref"
        @selection-change="handleSelectionChange"
        v-loading="loadingPage"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <!--<el-table-column   prop="id" label="管理员编号" show-overflow-tooltip></el-table-column>-->
        <el-table-column
          min-width="70"
          prop="name"
          label="用户名称"
        ></el-table-column>
        <el-table-column prop="username" label="用户账号"> </el-table-column>
        <el-table-column prop="mobile" label="手机号码"> </el-table-column>
        <el-table-column prop="user_dept" label="所属部门"> </el-table-column>
        <el-table-column prop="evaluate_status_label" label="评估状态">
          <template #default="scope">
            <span class="tableSpanSty">{{ scope.row.evaluate_status_label }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="warning_level_label" label="预警等级">
          <template #default="scope">
            <span class="tableSpanSty">{{ scope.row.warning_level_label }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="description" label="预警原因">
        </el-table-column>
        <el-table-column prop="create_datetime" label="上报日期">
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="180">
          <template #header>
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div>操作</div>
              <div @click="setFull">
                <el-tooltip content="全屏" placement="bottom">
                  <el-icon><full-screen /></el-icon>
                </el-tooltip>
              </div>
            </div>
          </template>
          <template #default="scope">
            <span
              class="table-operate-btn"
              @click="handleEdit(scope.row, 'detail')"
              >查看详情</span
            >
                  <span
              class="table-operate-btn"
              @click="handleEdit(scope.row, 'edit')"
              >编辑</span
            >
                    <span
              class="table-operate-btn"
              @click="handleEdit(scope.row, 'delete')"
              >删除</span
            >
              <span       
              class="table-operate-btn"
              @click="handleEdit(scope.row, 'look')">查看报告</span>
             <span       
              class="table-operate-btn"
              @click="handleEdit(scope.row, 'download')">下载报告</span>
           
   
           <!--    <span
              class="table-operate-btn"
              @click="handleEdit(scope.row, 'status')"
              v-if="scope.row.status !== '3'"
              >完成任务</span
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      v-bind:child-msg="pageparm"
      @callFather="callFather"
    ></Pagination>
    <addearlyWarning
      :evaluate_statusList="evaluate_statusList"
      :warning_levelList="warning_levelList"
      ref="addearlyWarningflag"
      @refreshData="getData"
    ></addearlyWarning>
    <warningDetail ref="warningDetailflag"></warningDetail>
  </div>
</template>
<script>
import addearlyWarning from "./components/addearlyWarning";
import warningDetail from "./components/warningDetail";
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight ,setStore} from "@/utils/util";
import {useMutitabsStore} from "@/store/mutitabs";
import { warningStget, reportAbbrget,warningStdelete, productsget, UsersUsers, options, warningStput, deptSchoolPeo } from '@/api/api'
export default {
  components: {
    Pagination,
    addearlyWarning,
    warningDetail
  },
  name: 'earlyWarning',
  setup() {
    const mutitabsstore = useMutitabsStore()
    return {mutitabsstore}
  },
  data() {
    return {
      warning_levelList:[],
      evaluate_statusList:[],
      optionschool:[],
      props: { multiple: true,value:'id', label:'name'},
      options: [],

      objArrDetle: [],
      userlist: [],// pingce
      productsgetlist: [], // fangam
      isFull: false,
      visible: false,
      tableHeight: 500,
      loadingPage: false,
      formInline: {
        dept_id: '',
        name: '',
        mobile: '',
        warning_level: '',
        evaluate_status: '',
        page: 1,
        limit: 10,
      },
      pageparm: {
        page: 1,
        limit: 10,
        total: 0
      },
      statusList: [],
      timers: [],
      tableData: []
    }
  },
  methods: {
    handlecascader(e) {
      let arr = []
      e.forEach(i => { 
        arr.push(i[i.length-1])
      })
      this.formInline.dept_id = arr.join(',')
     },
    handleDelteAll() {
      if (this.objArrDetle.length) {
        let vm = this
        vm.$confirm('您确定要删除选中的内容么？', {
          closeOnClickModal: false
        }).then(res => {
          warningStdelete({ id: this.objArrDetle.join(',') }).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.search()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      }

    },
    handleSelectionChange(e) {
      this.objArrDetle = []
      e.forEach(i => {
        this.objArrDetle.push(i.id)
      })
      // // console.log(e)
    },
    getSchoolData() {
      deptSchoolPeo({limit: 999,page: 1
      }).then(res => {
        // console.log(res.data)
        this.options = res.data.data
      })
    },
    getoptions() {
      options({ parent__isnull: true,   page: 1,
        limit: 999,title: '预警评估状态' }).then(res => {
        this.evaluate_statusList = res.data.data[0].children
      })
     options({ parent__isnull: true,  page: 1,
        limit: 999, title: '预警级别' }).then(res => {
        this.warning_levelList = res.data.data[0].children
      })
    },
    // 表格序列号
    getIndex($index) {
      // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
      return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
    },
    setFull() {
      this.isFull = !this.isFull
      window.dispatchEvent(new Event('resize'))
    },
    addAdmin() {
      this.$refs.addearlyWarningflag.addAdminFn(null, '新增')
    },
    handleEdit(row, flag) {
      if (flag == 'look') {
        reportAbbrget(row.report_abbr).then(res => {
          setStore('fileUrl', res.report_addr)
          this.$router.push({ path: '/filePreview' })
        })
      }
      else
      if (flag == 'download') {
        reportAbbrget(row.report_abbr).then(res => {

            window.open(res.report_addr, '_blank');
      
        })
        //report_abbr iframePdf /api/plan/sreport/report_abbr/
        // setStore('reportViewPlanId',row.plan)
        // let urlll = this.$router.resolve({ path: '/reportView' })
        // window.open(iframePdf + '/api/plan/sreport/' + row.report_abbr + '/', '_blank');
      } else  if (flag == 'edit') {
        this.$refs.addearlyWarningflag.addAdminFn(row, '编辑')
      }
      else if (flag == 'delete') {
        let vm = this
        vm.$confirm('您确定要删除选中的内容么？', {
          closeOnClickModal: false
        }).then(res => {
          warningStdelete(row).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.search()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      } else if (flag == 'detail') {
        // this.$router.push({ path: '/warningDetail'})
        this.mutitabsstore.setwarningDetailitem(row)
        this.$refs.warningDetailflag.addUserFn(row,'详情')
      }  else if (flag == "reset") {
        this.optionschool= []
        this.formInline = {
         dept_id: '',
        name: '',
        mobile: '',
        warning_level: '',
        evaluate_status: '',
        page: 1,
        limit: 10,
        }
        this.pageparm = {
          page: 1,
          limit: 10,
          total: 0
        }
        this.getData()
      }
    },

    callFather(parm) {
      this.formInline.page = parm.page
      this.formInline.limit = parm.limit
      this.getData()
    },
    search() {
      this.formInline.page = 1
      this.formInline.limit = 10
      this.getData()
    },

    async getDataproductsget() {
      productsget({ page: 1, limit: 999 }).then(res => {
        if (res.code == 2000) {
          this.productsgetlist = res.data.data
        }else { 
          this.$message.warning(res.msg)
        }
      })
    },
    //获取列表
    async getData() {
      this.loadingPage = true
      warningStget(this.formInline).then(res => {
        this.loadingPage = false
        if (res.code == 2000) {
          this.tableData = res.data.data

          this.pageparm.page = res.data.page;
          this.pageparm.limit = res.data.limit;
          this.pageparm.total = res.data.total;
        } else { 
          this.$message.warning(res.msg)
        }
      })
    },

    timeChange(val) {
      if (val) {
        this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    // 计算搜索栏的高度
    listenResize() {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight() {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight + 50 : 0
      tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight = getTableHeight(tabSelectHeight)
    }
  },
  created() {
    this.getData()
    this.getDataproductsget()
    this.getoptions()
    this.getSchoolData()

  },
  mounted() {
    // 监听页面宽度变化搜索框的高度
    window.addEventListener('resize', this.listenResize);
    this.$nextTick(() => {
      this.getTheTableHeight()
      
    })
  },
  unmounted() {
    // 页面销毁，去掉监听事件
    window.removeEventListener("resize", this.listenResize);
  },
}
</script>
<style>
.tableSpanSty {
  color: coral;
}
.appdownload {
  display: flex;
  justify-content: flex-end;
}
</style>
