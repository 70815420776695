<template>
  <div class="htmlreportView" id="htmlreportView" >
    <div class="stylePdf" @click="pdferport" v-if="pdferportFlag">导出pdf</div>
        <!-- <div class="stylePdf" @click="worderport" v-if="pdferportFlag">导出word</div> -->
    <div id="pdfHtmlQingDao" class="reportView">
      <div class="card-title">{{ planData.report_title }}</div>
      <div class="headerTitle">一、基本信息</div>
      <div class="headerTitle-actions">
        姓名：{{ planData.name }}  性别：{{planData.gender }}  同住人：{{ planData.with_who }}
      </div>
      <div class="headerTitle-actions">
        学校：{{ planData.school }}  班级：{{planData.grade }} {{ planData.class }} 
      </div>
      <div class="headerTitle-actions">
        测评量表：{{ planData.product }}  测评日期：{{planData.plan_date }}
      </div>
      <!-- 测评日期：{{ planData.plan_date }} -->
      <div class="headerTitle">二、家长须知</div>
      <div class="headerTitle-actions">
        主要评估内容：情绪（焦虑、抑郁、情绪稳定性）、人际关系、心理资本、内外向、自我价值和应对方式。
      </div>
      <div class="headerTitle-actions">
        请注意，每份心理测查结果的可靠性，部分取决于受测者当时的状态。根据作答质量指标，本次测评的可靠程度为
      {{  planData.score_kexin === 1 ? '🌟' :
          planData.score_kexin === 2 ? '🌟🌟' :
          planData.score_kexin === 3 ? '🌟🌟🌟' :
          planData.score_kexin === 4 ? '🌟🌟🌟🌟' :
          planData.score_kexin === 5 ? '🌟🌟🌟🌟🌟' : ''
      }} （可信程度最低为一颗星，最高为三颗星）
      </div>
      <div class="headerTitle-actions">
        很荣幸与你相遇，参与并关注{{planData.name}}的心理成长。只有了解孩子的需要、个性与天赋，才能真正做到因材施教，有效帮助并及时引导孩子，让孩子散发自身的光芒。只有洞察孩子的心理健康水平，才能提供及时有效的帮助，助力其健康成长。我们用心关爱孩子，用专业保障孩子的心理健康。
      </div>
      <div class="headerTitle-actions">你在阅读本报告时需要注意以下几点：</div>
      <div class="headerTitle-actions">
        1、本次测评结果只反应孩子受测时的状态，请辩证看待测评结果。
      </div>
      <div class="headerTitle-actions">
        2、本次测评结果中的情绪和人际困扰等不能作为医学临床诊断的依据。
      </div>
      <div class="headerTitle-actions">
        3、若本次测评结果与你平时对孩子的了解有偏离，不必过分担心。任何一次心理测评的结果会受到作答者答题时的心理状态、施测环境、施测流程等多方面因素的影响。
      </div>
      <div class="headerTitle-actions">
        4、文中给出的建议是针对大多数人的，实际使用过程中需要进行个性化指导。
      </div>
      <div class="headerTitle-actions">
        5、孩子的发展是动态的，请勿以一次测评的结果来给孩子贴上标签。
      </div>
      <div class="headerTitle-actions" style="margin-bottom: 30px">
        感谢你的信任与支持！
      </div>
      <div class="headerTitle">三、总体情况</div>
      <div class="headerTitle-actions" v-if="planData.level_label === '发展良好'">
        你好！测评结果显示{{planData.name}}的心理状态为
        <span style="font-weight: 700">【{{planData.level_label}}】</span
        >孩子的成长不仅只有学业成绩，身心健康对其长远发展至关重要。家长需要以更全面的眼光来看待孩子，关注其情绪状态、交友状况、内心感受等，做孩子健康成长的帮手，助其维系良好的发展状态。
      </div>
         <div class="headerTitle-actions"  v-if="planData.level_label === '需要关心'">
        你好！测评结果显示{{planData.name}}的心理状态为
        <span style="font-weight: 700">【{{planData.level_label}}】</span
        >孩子近期的心理状态可能有些波动，虽然对正常的学习和生活影响不大，但是也需要家长和教师的关注，望你知晓。如果可能，请多与孩子沟通，去了解孩子的学习和生活中是否有可以帮助的地方，要倾听孩子的语言，尽可能少地评价孩子，尤其是负面评价。孩子的成长，离不开父母的积极参与。
      </div>
         <div class="headerTitle-actions" v-if="planData.level_label === '重点关怀'">
        你好！测评结果显示{{planData.name}}的心理状态为
        <span style="font-weight: 700">【{{planData.level_label}}】</span
        >孩子近期的情绪存在一定的波动，体验到较高水平的负性情绪，与同学的相处感受到一定程度的困难，对自我存在负面认知，可能对其正常的学习和生活造成了影响，不利于其潜力的发挥。孩子的状况需要家长和教师的重点关注，望你知晓。如果可能，请多与孩子沟通，去了解孩子的情况，多给予孩子有效的陪伴。
      </div>
         <div class="headerTitle-actions" v-if="planData.level_label === '亟待关护'">
        你好！测评结果显示{{planData.name}}的心理状态为
        <span style="font-weight: 700">【{{planData.level_label}}】</span
        >孩子近期的负性情绪比较明显，情绪波动较大，在与同学相处的过程中体验到了一定程度的困难，对自我价值的认识偏低。这对其正常的学习和生活造成了影响，不利于其潜力的发挥。孩子的状况需要家长和教师的关注，望你知晓。如果可能，请多与孩子沟通。如果有必要，可以寻求学校和心理学专业人员的帮助。
      </div>
      <div class="fujian3">
        <div
          class="fujian3-1"
          v-for="(item, index) in fujian3"
          :key="index"
          :style="'background:' + item.color"
        >
          <span class="fujian3-1-1">{{ item.name }} </span>
          <img
            class="fujian3-1-1img"
            v-if="index+1 === planData.level"
            src="@/assets/peo1.png"
            alt=""
          />
        </div>
      </div>
      <div class="headerTitle-actions">
        家长需要以发展的眼光来看待孩子，关注其情绪状态、交友状况、内心感受等，做孩子健康成长的帮手，助其维系良好的发展状态。
      </div>
      <div class="headerTitle">四、各项详情</div>
      <div class="headerTitle">1、情绪困扰</div>
      <div class="headerTitle-actionsno">
        本报告的情绪困扰分成焦虑、抑郁和情绪稳定性三个方面。
      </div>
      <div class="headerTitle">焦虑</div>
      <div class="headerTitle-actions">
        现代生活中，焦虑彷佛已经成为我们的公敌。然而，焦虑和焦虑症不一样，每个人的生活中都有感到焦虑的时候。焦虑也未必是坏事。在心理学家眼里，焦虑是面对潜在挑战或威胁时大脑给我们发出的信号。在人类进化的过程中，正是恼人的焦虑帮助我们更敏锐地识别出潜在的威胁，并为应对威胁做好准备。
      </div>
      <div class="headerTitle-actions">
        适当焦虑是有用的，有利于提前做好准备，提高学习和工作效率。然而，长期过度焦虑，会损害身体和心理健康，甚至可能发展成焦虑症这样的心理障碍。
      </div>
      <div class="headerTitle-actionsno">
        {{planData.name}}的焦虑分数为{{planData.jiaolv}}，高于{{parseInt(planData.jiaolv_percent * 100)}}%的本年级学生
      </div>
      <div class="fujian4">
        <div class="fujian4-1">
           <img class="fujian4-3" :style="'left:calc(' + planData.jiaolv + '% - 15px)'" src="@/assets/peo1.png" alt="" />
        </div>
        <div class="fujian4-2">
          <span class="fujian4-2-1">焦虑感不明显</span>
          <span class="fujian4-2-1">焦虑感明显</span>
        </div>
       
      </div>
      <div class="headerTitle-actionsno">
        测评用户中，{{ planData.jiaolv_label === '焦虑感明显' ?  planData.jiaolv_label3_percent : planData.jiaolv_label === '焦虑感较明显' ? planData.jiaolv_label2_percent :
        planData.jiaolv_label === '焦虑感不明显' ? planData.jiaolv_label1_percent : ''}}%的人和你一样
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        焦虑感明显 {{planData.jiaolv_label3_percent}}%
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        焦虑感较明显  {{planData.jiaolv_label2_percent }}%
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        焦虑感不明显 {{planData.jiaolv_label1_percent }}%
      </div>
      <div class="headerTitle">建议</div>
      <div class="headerTitle-actionsno">每天十分钟正念练习，有效缓解焦虑</div>
      <div class="headerTitle-actions">
        心理学领域的知名期刊《临床心理学年鉴》总结到，长期的正念练习对于改善焦虑的效果可以媲美专业的心理咨询。正念练习可以减低杏仁核的活跃程度，杏仁核脑区和情绪的诞生有关，当它活跃的时候，会让人产生害怕、恐惧的情绪。
      </div>
      <div class="headerTitle-actions">
        正念要求我们把注意力放在此时此刻的感受上，不对这些感受进行评价。正念练习过程中也可能会出现焦虑。请记住我们不必去排斥出现的焦虑情绪，而是去接纳它存在，去直面它，把它当成脑海里飘过的思绪。
      </div>

      <div class="headerTitle-actions">
        在不断的练习后，我们可以慢慢学会和焦虑共处。
      </div>
      <div class="headerTitle">抑郁</div>
      <div class="headerTitle-actions">
        你可能会觉得抑郁就是悲伤、独自流泪这类情绪低落的状况。的确，情绪低落是抑郁的一个特征，但这样理解抑郁是不够全面。
      </div>
      <div class="headerTitle-actions">
        抑郁更隐蔽的特征，是快乐感受降低，精力丧失，认知功能下降，看不到希望等。处于抑郁状态的人，心里可能出现一些不健康的声音，比如“再怎么努力都是徒劳”，“一切都没意思”，“我不行”，“我看不到希望”……他们比平时更容易疲惫，饮食睡眠的规律可能也会发生变化。
      </div>
      <div class="headerTitle-actions">
        周围的人可能难以觉察出他们的痛苦，可能会误以为他们变懒了。请记住，他们并不是真的懒惰——抑郁是伴随着神经递质和激素变化的。你也许觉得他无法做到其他人眼中简单的事，哪怕不理解，也不能指责或嘲笑。
      </div>
      <div class="headerTitle-actions">
        每个人的生活中，都会面临压力，每个人都或多或少体验过抑郁情绪。然而，普通人体会到的抑郁情绪和临床意义上的抑郁症大不同。
      </div>
      <div class="headerTitle-actions">
        即便是罹患抑郁症，医学和心理学也是可以提供帮助的。
      </div>
      <div class="headerTitle-actionsno">
         {{planData.name}}的抑郁情绪分数为{{planData.yiyu}}，高于{{parseInt(planData.yiyu_percent * 100)}}%的本年级学生
      </div>
      <div class="fujian4">
          <div class="fujian4-1">
           <img class="fujian4-3" :style="'left:calc(' + planData.yiyu + '% - 15px)'" src="@/assets/peo1.png" alt="" />
        </div>
        
        <div class="fujian4-2">
          <span class="fujian4-2-1">抑郁情绪不明显</span>
          <span class="fujian4-2-1">抑郁情绪明显</span>
        </div>
      </div>
      <div class="headerTitle-actionsno">
        测评用户中，{{ planData.yiyu_label === '抑郁情绪明显' ?  planData.yiyu_label3_percent : planData.yiyu_label === '抑郁情绪较明显' ? planData.yiyu_label2_percent :
        planData.yiyu_label === '抑郁情绪不明显' ? planData.yiyu_label1_percent : ''}}%的人和你一样
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        抑郁情绪明显 {{planData.yiyu_label3_percent}}%
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        抑郁情绪较明显  {{planData.yiyu_label2_percent}}%
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        抑郁情绪不明显 {{planData.yiyu_label1_percent}}%
      </div>
      <div class="headerTitle">建议：</div>
      <div class="headerTitle-actionsno">
        「3，20，2」 锻炼改善抑郁的三个数字
      </div>
      <div class="headerTitle-actions">
        适量的运动是改善负性情绪最有用的方式之一。那么我们该怎么运动来改善情绪呢？这里有三个神奇的数字，请牢记：
      </div>
      <div class="headerTitle-actions">
        第一个数字是3，意思是每周进行3次运动锻炼。
      </div>
      <div class="headerTitle-actions">
        第二个数字是20，意思是每次锻炼时长至少为20分钟。需要注意，这20分钟的运动锻炼需要达到一定的强度，最好能够出点汗。
      </div>
      <div class="headerTitle-actions">
        最后一个数字是2，意思是这样的锻炼要至少持续2周，情绪才会有所改善。
      </div>
      <div class="headerTitle">情绪稳定性</div>
      <div class="headerTitle-actions">
        情绪稳定性可以理解成一个人对生活中的消极信息是否敏感。作为一种独特的人格倾向，无论情绪稳定性是高是低，都有各自的力量。
      </div>
      <div class="headerTitle-actions">
        情绪稳定性较高的人，对消极信息不那么敏感。产生的感受也不特别强烈，比较容易恢复平静。他们能够把更多的时间和精力放在自己感兴趣的事物上，而不需要花大量的时间来处理自己的情绪困扰。
      </div>
      <div class="headerTitle-actions">
        情绪稳定性较低的人，对消极信息敏感，更容易体验到强烈的消极情绪。对威胁性的信息更敏感，其实能提醒我们对之进行有效规避，并找到适合自己的生存空间。他们还能将丰富的情绪体验通过各种艺术形式传递出来。
      </div>
      <div class="headerTitle-actionsno">
           {{planData.name}}的情绪稳定分数为{{planData.qingxu}}，高于{{parseInt(planData.qingxu_percent * 100)}}%的本年级学生
      </div>
      <div class="fujian4">
  <div class="fujian4-1">
           <img class="fujian4-3" :style="'left:calc(' + planData.qingxu + '% - 15px)'" src="@/assets/peo1.png" alt="" />
        </div>
        <div class="fujian4-2">
          <span class="fujian4-2-1">情绪不稳定</span>
          <span class="fujian4-2-1">情绪稳定</span>
        </div>
      </div>
      <div class="headerTitle-actionsno">
            测评用户中，{{ planData.qingxu_label === '情绪稳定' ?  planData.qingxu_label3_percent  : planData.qingxu_label === '情绪较稳定' ? planData.qingxu_label2_percent :
        planData.qingxu_label === '情绪不稳定' ? planData.qingxu_label1_percent : ''}}%的人和你一样
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        情绪稳定 {{planData.qingxu_label3_percent}}%
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        情绪较稳定 {{planData.qingxu_label2_percent}}%
      </div>
      <div
        class="headerTitle-actionsno"
        style="font-weight: 700; margin-bottom: 30px"
      >
        情绪不稳定 {{planData.qingxu_label1_percent}}%
      </div>
      <div class="headerTitle">建议：</div>
      <div class="headerTitle-actionsno">1、深呼吸</div>
      <div class="headerTitle-actions">
        深呼吸能够唤醒我们的副交感系统，让我们感到放松，让身心重聚能量。
      </div>
      <div class="headerTitle-actions">
        深呼吸简单有效，但大多数人都需要学习才能掌握深呼吸的诀窍。深呼吸的步骤可以分成三步：
      </div>
      <div class="headerTitle-actions">
        第一、吸气。用腹部深沉而缓慢地吸一口气。去感受空气进入身体之后，腹部慢慢鼓起来的感觉，去感受它是如何变大、变胀，并最终被空气填满。
      </div>
      <div class="headerTitle-actions">
        第二、憋气。感受腹部此时此刻的感觉，持续1- 4 秒都可以。
      </div>
      <div class="headerTitle-actions">
        第三、呼气。慢慢地把气呼出来，呼气的时间要和吸气的时间差不多长。呼气时，用鼻翼去感受温热的空气流过的感觉。
      </div>
      <div class="headerTitle-actions">
        耐心重复这个步骤五分钟，你就能够体会到神奇的效果。
      </div>
      <div class="headerTitle-actionsno">
        2、可以尝试着写笔记，把自己的感受写出来。
      </div>
      <div class="headerTitle">2、人际困扰</div>
      <div class="headerTitle-actions">
        小贴士：2017年，教育部等十一个部门联合印发《加强中小学生欺凌综合治理方案》，该方案指出中小学生欺凌是发生在中小学校和中等职业学校内外、学生之间，一方（个体或群体）单次或多次蓄意或恶意通过肢体、语言及网络等手段实施欺凌、侮辱，造成另一方（个体或群体）身体伤害、财产损失或精神损害等的事件。
      </div>
      <div class="headerTitle-actions">
        校园欺凌行为有很多种，我们熟知的一些欺凌行为，如打、踢、抢夺他人财物，属于身体欺凌。但是你知道吗？还有另外两类欺凌。其中一类是言语欺凌，如威胁、取笑或取侮辱性的外号等；另一类是关系欺凌，以伤害他人的社交关系为目标，例如排挤、散步谣言等。许多研究表明，欺凌不仅对被欺凌者的身心健康造成伤害，对欺凌者的发展同样有不良的影响。当欺凌发生时，旁观者的做法特别重要。大多数情况下，欺凌发生时如果有个旁观者能够说一声“嘿，XXX（欺凌者的名字），你在干什么”，可能就能够有效制止欺凌行为。
      </div>
      <div class="headerTitle-actionsno">
         {{planData.name}}的人际困扰分数为{{planData.jiaoyou}}，高于{{parseInt(planData.jiaoyou_percent * 100)}}%的本年级学生
      </div>
      <div class="fujian4" style="margin-bottom: 70px">
  <div class="fujian4-1">
           <img class="fujian4-3" :style="'left:calc(' + planData.jiaoyou + '% - 15px)'" src="@/assets/peo1.png" alt="" />
        </div>
        <div class="fujian4-2">
          <span class="fujian4-2-1">人际困扰不明显</span>
          <span class="fujian4-2-1">人际困扰明显</span>
        </div>
      </div>
      <div class="headerTitle-actionsno">
         测评用户中，{{ planData.jiaoyou_label === '人际困扰明显' ?  planData.jiaoyou_label3_percent  : planData.jiaoyou_label === '人际困扰较明显' ? planData.jiaoyou_label2_percent  :
        planData.jiaoyou_label === '人际困扰不明显' ? planData.jiaoyou_label1_percent : ''}}%的人和你一样
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        人际困扰明显 {{planData.jiaoyou_label3_percent}}%
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        人际困扰较明显 {{planData.jiaoyou_label2_percent}}%
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        人际困扰不明显 {{planData.jiaoyou_label1_percent}}%
      </div>
      <div class="headerTitle">建议：</div>
      <div class="headerTitle-actionsno">1、制定具体的交友目标</div>
      <div class="headerTitle-actions">
        每个人都需要朋友，但如果只是口头上说“我想交更多的朋友”，更多的朋友并不会自动来到身边。一般来说，我们更常和谁在一起，就更容易和谁成为朋友。因此，也许可以考虑“我明天要约小明一起吃饭”，“周末我想约跟晓东一起去打球”这类详细的、可操作的计划，并去执行它。
      </div>
      <div class="headerTitle-actionsno">2、多参加集体活动，多为集体出力</div>
      <div class="headerTitle-actions">
        一起参加一次激烈的球赛，或者一起去户外徒步，共同拼搏或者一同跋山涉水的经历也能拉近你和同伴的距离，让同伴了解你。当然，参加户外徒步需要有专业的向导。
      </div>
      <div class="headerTitle-actions">
        多去看同伴的优点，也不要因此而过分担心自己的缺点和不足，毕竟我们每个人都有缺点。
      </div>
      <div class="headerTitle">3、人格特征</div>
      <div class="headerTitle-actions">
        本部分测查了孩子的内外向和应对方式，具体情况如下：
      </div>
      <div class="headerTitle">内外向</div>
      <div class="headerTitle-actions">
        内向者对社交的需求不太高，太多的社交会让内向者感到劳累，消耗能量。他们需要的外部刺激不多，能享受独处的时光。对他们来说，独处看小说、刷剧、玩游戏甚至是独自发呆都是更好的放松方式。内向的人也需要朋友，但是内向的人喜欢交几个关系比较密切的朋友。
      </div>
      <div class="headerTitle-actions">
        外向者从社交中获得快乐与能量，愿意花时间参与社交活动。对他们来说，和他人一起才是最好的放松方式，长时间独处会让他们感觉不自在。外向的人一般朋友的数量会比较多。
      </div>
      <div class="headerTitle-actions">
        内外向是一个连续的谱系，绝大多数人处在中间状态，一些人会更偏向某一端。内外向的稳定性是相对的，在成长过程中是可以多多少少改变的。
      </div>
      <div class="headerTitle-actionsno">
           {{planData.name}}的内外向分数为{{planData.neiwai}}，高于{{parseInt(planData.neiwai_percent * 100)}}%的本年级学生
      </div>
      <div class="fujian4">
  <div class="fujian4-1">
           <img class="fujian4-3" :style="'left:calc(' + planData.neiwai + '% - 15px)'" src="@/assets/peo1.png" alt="" />
        </div>
        <div class="fujian4-2">
          <span class="fujian4-2-1">非常内向</span>
          <span class="fujian4-2-1">非常外向</span>
        </div>
      </div>
      <div class="headerTitle-actionsno">
         测评用户中，{{ planData.neiwai_label === '偏外向' ?  planData.neiwai_label3_percent  : planData.neiwai_label === '内外兼具' ? planData.neiwai_label2_percent :
        planData.neiwai_label === '偏内向' ? planData.neiwai_label1_percent : ''}}%的人和你一样
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        偏外向 {{planData.neiwai_label3_percent}}%
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        内外兼具 {{planData.neiwai_label2_percent}}%
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        偏内向 {{planData.neiwai_label1_percent}}%
      </div>
      <div class="headerTitle">建议：</div>
      <div class="headerTitle-actionsno">1、认可外向的价值</div>
      <div class="headerTitle-actions">
        爱说话，爱交际，喜欢和人在一起，并不是肤浅的表现。这些刻板印象其实是对外向的误解。外向的人完全可以同时拥有深刻、体贴和善于沟通的特质。
      </div>
      <div class="headerTitle-actionsno">2、认可内向的价值</div>
      <div class="headerTitle-actions">
        内向者并不一定是害羞或不善言辞的，只是对自己的内心世界更敏感。内向者更善于利用独处的时光，更有机会发挥艺术创造力和想象力，挖掘自己更丰富的内心。
      </div>
      <div class="headerTitle">应对方式</div>
      <div class="headerTitle-actions">
        应对方式是个体适应环境所采用方法或策略，有一定的跨情景一致性，同时也对心身健康有一定的影响。每个人的应对方式都自己的特点，涉及心理层面（如对事件进行再评价）、行为层面（如回避特定的场景）和生理层面（如提醒自己放松）。还可以把应对方式分成问题解决取向和情绪启动取向，二者有各自适用的场景。
      </div>
      <div class="headerTitle-actions">
        {{planData.name}}的问题解决取向分数为{{planData.yingdui_ja}}，高于{{parseInt(planData.yingdui_ja_percent * 100)}}%的本年级学生；{{planData.name}}的情绪启动取向分数为{{planData.yingdui_jb}}，高于{{parseInt(planData.yingdui_jb_percent * 100)}}%的本年级学生。
      </div>

      <!-- fujianwu -->
      <div class="fujian5">
        <div class="fujian5-1"></div>
        <div class="fujian5-2">
          <div class="fujian5-2-1">问题解决取向少</div>
          <div class="fujian5-2-1">问题解决取向多</div>
        </div>

        <div class="fujian5-1copy"></div>
        <div class="fujian5-2copy">
          <span class="fujian5-2-1">情绪启动取向多</span>
          <span class="fujian5-2-1">情绪启动取向少</span>
        </div>
        <img class="fujian5-3" :style="'left:calc(' + planData.yingdui_ja +  '% - 15px);top:calc(' + 400/planData.yingdui_jb + 'px + 30px)'"  src="@/assets/peo1.png" alt="" />
      </div>
      <div class="headerTitle">建议：</div>
      <div class="headerTitle-actionsno">1、应对方式集</div>
      <div class="headerTitle-actions">
        每面临或者处理完一次事件时，将处理过程中自己的应对方式记录下来，形成一个处理方式文集，这样可以很好地认识到自己常用的应对方式有哪些，自己能应用得很好的应对方式又有哪些。
      </div>
      <div class="headerTitle-actionsno">2、学习</div>
      <div class="headerTitle-actions">
        其他同学的生活中也会有很多的冲突场景，看看别人是怎么做的，他们处理冲突的手法是可以借鉴的。当然，也可以把自己的困惑告诉可信的家长或者老师，请求他们的建议和意见。
      </div>
      <div class="headerTitle">4、心理资本</div>
      <div class="headerTitle-actions">
        心理资本主要反映个体能够获得的社会支持。当个体有需要时，能否得到来自于他人的同情以及资源，从而使得个体的问题得到解决。
      </div>
      <div class="headerTitle-actions">
        社会支持可以分为情感支持、信息支持、陪伴支持及工具性支持。一些社会支持是客观、可见且实际的，而另一些则更加主观，表现为体验到的支持。
      </div>
      <div class="headerTitle-actionsno">
           {{planData.name}}的心理资本分数为{{planData.ziben}}，高于{{parseInt(planData.ziben_percent * 100)}}%的本年级学生
      </div>
      <div class="fujian4">
     <div class="fujian4-1">
           <img class="fujian4-3" :style="'left:calc(' + planData.ziben + '% - 15px)'" src="@/assets/peo1.png" alt="" />
        </div>
        <div class="fujian4-2">
          <span class="fujian4-2-1">心理资本一般</span>
          <span class="fujian4-2-1">心理资本丰富</span>
        </div>
      </div>
      <div class="headerTitle-actionsno">
        测评用户中，{{ planData.ziben_label === '心理资本丰富' ?  planData.ziben_label3_percent  : planData.ziben_label === '心理资本较丰富' ? planData.ziben_label2_percent  :
        planData.ziben_label === '心理资本一般' ? planData.ziben_label1_percent : ''}}%的人和你一样
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        心理资本丰富 {{planData.ziben_label3_percent}}%
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        心理资本较丰富 {{planData.ziben_label2_percent}}%
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        心理资本一般 {{planData.ziben_label1_percent}}%
      </div>
      <div class="headerTitle">建议：</div>
      <div class="headerTitle-actionsno">1、多和家人沟通</div>
      <div class="headerTitle-actions">
        有时候我们会觉得和父母沟通不那么容易，但是与家人保持畅通的交流还是重要的。如果爸爸妈妈比较喜欢打断你说话，请耐心地请求他们尽量不要打断你。你还可以告诉他们，尽量不要给出批评或者评价，你只是想收获一些建议。
      </div>
      <div class="headerTitle-actionsno">2、多参加集体活动</div>
      <div class="headerTitle-actions">
        集体活动是让同学和老师全面了解你，也让你充分了解同学和老师的好机会。人们往往更愿意对自己了解的人施以援手。集体活动也能让你和同学们有更多的共同话题。
      </div>
      <div class="headerTitle">5、自我价值感</div>
      <div class="headerTitle-actions">
        自我价值感是心理健康的重要指标之一，表明个体在多大程度上相信自己是有能力的、重要的和有价值的。
      </div>
      <div class="headerTitle-actions">
        一般而言，自我价值感比较强的人比较能够接纳自己，也更为愉快和健康，具有更好的身心调节能力；反之，自我价值感低的人对自我的接纳程度不高。自我价值感对青少年的发展非常重要。一方面，自我价值感的发展状况与个体的心理健康直接相联系；另一方面，自我价值感也会间接对个体的认知、动机、情感和社会行为产生影响。
      </div>
      <div class="headerTitle-actions">
        自我价值感是一个主观的感受，常常会脱离真实的状况。其实许多自我价值感偏低的人，并不是因为自己真的没有价值，而是因为没有看到自己的长处。
      </div>
      <div class="headerTitle-actions">
        每个人，都与众不同，都有自己独特的价值。
      </div>
      <div class="headerTitle-actionsno">
        {{planData.name}}的自我价值感分数为{{planData.jiazhi}}，高于{{parseInt(planData.jiazhi_percent * 100)}}%的本年级学生
      </div>
      <div class="fujian4">
     <div class="fujian4-1">
           <img class="fujian4-3" :style="'left:calc(' + planData.jiazhi + '% - 15px)'" src="@/assets/peo1.png" alt="" />
        </div>
        <div class="fujian4-2">
          <span class="fujian4-2-1">自我价值感一般</span>
          <span class="fujian4-2-1">自我价值感高</span>
        </div>
     </div>
      <div class="headerTitle-actionsno">
       测评用户中，{{ planData.jiazhi_label === '自我价值感高' ?  planData.jiazhi_label3_percent  : planData.jiazhi_label === '自我价值感较高' ? planData.jiazhi_label2_percent  :
        planData.jiazhi_label === '自我价值感一般' ? planData.jiazhi_label1_percent : ''}}%的人和你一样
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        自我价值感高 {{planData.jiazhi_label3_percent}}%
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        自我价值感较高 {{planData.jiazhi_label2_percent}}%
      </div>
      <div class="headerTitle-actionsno" style="font-weight: 700">
        自我价值感一般 {{planData.jiazhi_label1_percent}}%
      </div>
      <div class="headerTitle">建议：</div>
      <div class="headerTitle-actionsno">1、做一些能够有助于自己成长的事情</div>
      <div class="headerTitle-actions">
        成长是一个缓慢，但是每天都在进行的事情。成长过程中，最重要的密码是坚持。去持之以恒地做一件事情。当然，做事情的态度也很重要，要怀着“学习”的心态，而不是“证明自己”的心态。不仅对待日常的学习是如此，对待日常生活中的每一件事情都可以这样去考虑。
      </div>
      <div class="headerTitle-actionsno">2、建立良好的行为习惯</div>
      <div class="headerTitle-actions">
        培养健康良好的行为习惯，有助于提升自我价值。合理的饮食，规律的睡眠和体育锻炼，对于提升自我价值感也有帮助。
      </div>
      <div class="headerTitle-actions">
        每个人都是不同的，报告中提及的建议措施的有效性因人而异。希望你不要把这些建议作为帮助孩子的唯一参考信息，你可以做多种尝试，找到适合你自己孩子的方法。
      </div>
      <!-- <footer class="footer">
        <p>Copyright © 2024 中科心馨社会心理服务平台 - All right reserved</p>
      </footer> -->
        <!-- -moz-user-select:none; /* Firefox */
  -webkit-user-select:none; /* WebKit内核 */
  -ms-user-select:none; /* IE10及以后 */
  -khtml-user-select:none; /* 早期浏览器 */
  -o-user-select:none; /* Opera */
  user-select:none; /* CSS3属性 */ -->
    </div>
  </div>
</template>
<style scoped>
.fujian5 {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 6% 0px;
}
.fujian5-3 {
  position: absolute;
  width: 30px;
  top: 20%;
  left: 20%;
}
.fujian5-1 {
  width: 100%;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    to right,
    rgba(72, 116, 203, 0.1),
    rgba(72, 116, 203, 1)
  );
}
.fujian5-1copy {
  width: 20px;
  height: 400px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    to bottom,
    rgba(72, 116, 203, 0.1),
    rgba(72, 116, 203, 1)
  );
}
.fujian5-2copy {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
  position: absolute;
  top: 50%;
  left: 61%;
  transform: translate(-50%, -50%);
}
.fujian5-2 {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.fujian5-2-1 {
  line-height: 2;
}
.fujian4 {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 20px 10%;
  position: relative;
  margin: 5% 0;
}
.fujian4-3 {
  position: absolute;
  width: 30px;
  top: -3vh;
  left: 20%;
}
.fujian4-1 {
  width: 100%;
  height: 20px;
  position: relative;
  background: linear-gradient(
    to right,
    rgba(72, 116, 203, 0.1),
    rgba(72, 116, 203, 1)
  );
}
.fujian4-2 {
  display: flex;
  justify-content: space-between;
}
.fujian4-2-1 {
  line-height: 2;
}
.fujian3 {
  width: 90%;
  padding: 10%;
  height: 15px;
  display: flex;
}
.fujian3-1 {
  flex: 1;
  text-align: center;
  position: relative;
}
.fujian3-1-1 {
  position: relative;
  top: 20px;
}
.fujian3-1-1img {
  position: absolute;
  top: -30px;
  left: 39%;
  width: 30px;
}
.headerTitle-actionsno {
  line-height: 1.8;
  font-size: 1.3rem;
  width: 100%;
}
.headerTitle-actions {
  text-indent: 2rem;
  line-height: 1.8;
  font-size: 1.3rem;
  width: 100%;
}
.headerTitle {
  font-size: 1.5rem;
  padding: 2rem 0;
  font-weight: 700;
  width: 100%;
}
.stylePdf {
  cursor: pointer;
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
}
.footer {
  display: grid;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.htmlreportView {
  width: 100%;
  height: 100%;

}
.reportView {
  padding: 0 18%;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
}
.card-title {
  font-size: 2rem;
  line-height: 2;
  font-weight: bold;
}
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.card-actions {
  font-size: 1.5rem;
  line-height: 2;
}
</style>
<script>
// 引入水印文件地址
import watermark from '@/utils/watermark.js'
   import {useMutitabsStore} from "@/store/mutitabs";
        import FileSaver from "file-saver";
  import htmlDocx from "html-docx-js/dist/html-docx"; //导出word
import { getStore, removeStore } from "@/utils/util";
import { getreportViewinfo } from '@/api/api'
import { downloadPDF } from "@/utils/pdf.js"  //工具方法，导出操作
export default {
     setup(){
            const mutitabsstore = useMutitabsStore()
            return { mutitabsstore}
        },
  name: 'reportView',
  data() {
    return {
      pdferportFlag:true,
      planData: {},
      planId: '',
      fujian3: [{
        name: '发展良好',
        color: 'rgb(46,84,161)'
      }, {
        name: '需要关心',
        color: 'rgb(88,182,49)'
      }, {
        name: '重点关怀',
        color: 'rgb(255,192,0)'
      }, {
        name: '亟待关护',
        color: 'rgb(192,0,0)'
      }]

    }
  },
  created() {
    this.planId = getStore('reportViewPlanId')
    // console.log(this.planId)
    this.getData(this.planId)
    this.$nextTick(() => { 
        watermark.set(this.mutitabsstore.userName,document.getElementById('#pdfHtmlQingDao'))
    })
    
  },
  beforeUnmount() {
    // removeStore('reportViewPlanId')
     watermark.set('')
  },
  methods: {
    worderport() { 
            this.pdferportFlag = false
      var html = document.getElementById('htmlreportView').innerHTML;
  
          //将图片转base64,这样word就能显示图片信息了
          var imgReg =  /<img.*?(?:>|\/>)/gi;
          var srcReg = /src=[\'\"\s]?([^\'\"]*)[\'\"\s]?/i;
          var matchs = html.match(imgReg);
          for (var i = 0; i< matchs.length; ++i) {
            var match = matchs[i];
            var src = match.match(srcReg);
            if (src && src[1]) {
              var imgSrc = src[1];
              var image = new Image();
              image.src = imgSrc;
              var canvas = document.createElement('canvas');
              // canvas.width = image.width;
              // canvas.height = image.height;
              canvas.width = 250;//自定义图片宽高-----（由于图片本身分辨率较大，导致无法加载。所以自定义一个比较小的值）
              canvas.height = 150;
              var ctx = canvas.getContext('2d');
              // ctx.drawImage(image, 0, 0);
              ctx.drawImage(image, 0, 0, 250, 150)
              var base64Url = canvas.toDataURL('image/jpeg', 1.0);
              html = html.replace(match, `<img src="${base64Url}" />` );
            }
          }
          //将图片转base64------END----------

          let contentHtml = html;
          let content = `
                <!DOCTYPE html><html>
                <head>
                    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                </head>
                <body>
                    <div>
                        ${contentHtml}
                    </div>
                </body>
            </html>`;
          let converted = htmlDocx.asBlob(content)
      FileSaver.saveAs(converted, name + ".docx")
      setTimeout(() => { 
    this.pdferportFlag = true
      },2000)
    },
    getData() {
      getreportViewinfo({ id: this.planId }).then(res => {
        console.log(res)
        this.planData = res
      })
    },
    pdferport() {
      this.pdferportFlag = false
      this.$nextTick(() => {
        downloadPDF({
          id: "app",
          fileName: `报告.pdf`,
          textBreakClassName: "roadMonitoringBreakClass",
          isDownLoad: true,
          isNeedFooter: false,
        });
        setTimeout(() => { 
          this.pdferportFlag = true
        },1000)
      })
    }
  }
}
</script>
