<template>
  <div>
    <ly-dialog
      v-model="dialogVisible"
      :title="loadingTitle"
      width="640px"
      :before-close="handleClose"
    >
      <el-form
        :inline="false"
        :model="formData"
        :rules="rules"
        ref="rulesForm"
        label-position="right"
        label-width="auto"
      >
        <el-form-item label="产品名称：" prop="product" required>
          <el-select
            v-model="formData.product"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, key) in deptProductList"
              :key="key"
              :label="item.product_name"
              :value="item.product_id"
            >
            </el-option>
          </el-select>
          <!-- <el-input placeholder="请输入" v-model="formData.name"></el-input> -->
        </el-form-item>
           <el-form-item label="接受授权机构：" prop="dept" required>
            <!-- depTreeUserList -->
          <!-- <el-select
            v-model="formData.dept_id"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, key, index) in deptProductList"
              :key="key"
              :label="item"
              :value="key"
            >
            </el-option>
          </el-select> -->
          <el-cascader
            style="width: 100%"
            v-model="optionschool"
            :options="depTreeUserList"
            :props="props"
            clearable
            @change="handlecascader"
          ></el-cascader>

        </el-form-item>
        <el-form-item label="产品授权状态：" prop="resale_status" required>
          <el-select
            v-model="formData.resale_status"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in statusList"
              :key="index"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
     
        <!-- <el-form-item label="授权日期范围：">
          <el-date-picker
            v-model="dateList"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item> -->
      </el-form>
      <template #footer>
        <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
        <el-button type="primary" @click="submitData" :loading="loadingSave"
          >确定</el-button
        >
      </template>
    </ly-dialog>
  </div>
</template>

<script>
import { useMutitabsStore } from "@/store/mutitabs";
import { productsresalepost, productsresaleputId } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import XEUtils from "xe-utils";
import { deepClone } from "@/utils/util";
export default {
  props: ['depTreeUserList', 'statusList','deptProductList'],
  components: { LyDialog },
  emits: ['refreshData'],
  name: "addStudentsEvaluate",
  setup(){
    const mutitabsstore = useMutitabsStore()
    return { mutitabsstore}
  },
  data() {
    return {
      optionschool:[],
      dateList: [],
      props: { multiple: false,value:'dept_id', label:'dept_name'},
      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
      formData: {
        product: '',
        resale_status: '',
        dept: ''
      },
      rules: {
       
        resale_status:[{ required: true, message: '请输入产品授权状态', trigger: 'change' }],
        // status: [{ required: true, message: '请输入产品状态', trigger: 'change' }],
        category: [{ required: true, message: '请输入产品类型', trigger: 'change' }],
        item_num: [{ required: true, message: '请输入题目数量', trigger: 'blur' }],
        product: [
          { required: true, message: '请输入产品名称', trigger: 'blur' }
        ],
        dept: [
          { required: true, message: '请授权机构', trigger: 'blur' }
        ]
      },
      rolelist: [],
      options: [],
    }
  },
  methods: {
    handlecascader(e) {
      this.formData.dept = e[e.length-1]
    },
    handleClose() {
      this.dialogVisible = false
      this.loadingSave = false
      this.formData = {
        product: '',
        resale_status: '',
        dept: ''
      }
    },
    addAdminFn(item, flag) {
      this.loadingTitle = flag
      this.dialogVisible = true
      this.formData = {
        product: '',
        resale_status: '',
        dept: ''
      }
      this.optionschool =[]
      // if(item && item.dept) {
      //     item.dept = item.dept.split(" ")
      // }
      if (item) {
        this.formData = deepClone(item)
      }

    },
    formatDate(cellValue) {
      if (cellValue == null || cellValue == "") return "";
      var date = new Date(cellValue)
      var year = date.getFullYear()
      var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      // + ' ' + hours + ':' + minutes + ':' + seconds
      return year + '-' + month + '-' + day
    },
    submitData() {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = {
            ...this.formData
          }
          // param['start_date'] = this.formatDate(this.dateList[0])
          // param['start_date'] = this.formatDate(this.dateList[1])
          // console.log(this.formData.id)
          if (this.formData.id) {
            productsresaleputId(Object.assign({
              owner_id:this.mutitabsstore.getuserDeptId
            },param)).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            productsresalepost(Object.assign({
              owner_id:this.mutitabsstore.getuserDeptId
            },param)).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
    },



  }
}
</script>

