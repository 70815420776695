<template>
  <div :class="{ 'ly-is-full': isFull }">
    <div class="tableSelect" ref="tableSelect">
      <el-form :inline="true" :model="formInline" label-position="left">
        <el-form-item label="部门查询：">
          <el-cascader
            v-model="optionschool"
            style="width: 260px"
            :options="options"
            :props="props"
            collapse-tags
            clearable
            @change="handlecascader"
          ></el-cascader>
        </el-form-item>

        <el-form-item label="测评方案：">
          <el-select
            size="default"
            v-model="formInline.product"
            placeholder="请选择"
            clearable
            style="width: 200px"
          
          >
            <el-option
              v-for="item in productsgetlist"
              :key="item.product_id"
              :label="item.product_name"
              :value="item.product_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务状态：">
           <el-select
            size="default"
            v-model="formInline.status"
            placeholder="任务状态"
            clearable
            style="width: 200px"
          
          >
            <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
          <!-- <el-input
            size="default"
            v-model.trim="formInline.statusList"
            maxlength="60"
            clearable
            placeholder="任务状态"
           
            style="width: 200px"
          ></el-input> -->
        </el-form-item>
        <el-form-item label="任务名称：">
          <el-input
            size="default"
            v-model.trim="formInline.name"
            maxlength="60"
            clearable
            placeholder="任务名称"
     
            style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item label="测评时间：" >
          <el-date-picker
            @change="handlerPicker"
            v-model="dateList"
            type="datetimerange"
            value-format="YYYY-MM-DD HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label=""
          ><el-button
            @click="search"
            type="primary"
            icon="Search"
            >搜索</el-button
          ></el-form-item
        >
        <el-form-item label=""
          ><el-button @click="handleEdit('', 'reset')" icon="Refresh"
            >重置</el-button
          ></el-form-item
        >
      </el-form>
      <!-- <div class="appdownload">
          <el-button  @click="search" type="primary" icon="Search" v-show="isShowBtn('adminManage','管理员管理','Search')">搜索</el-button> 
          <el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button>
          </div> -->
    </div>
    <div class="tableSelect">
      <el-button @click="addAdmin" type="primary" icon="Plus">新增</el-button>
      <el-button @click="handleDelteAll()" icon="Delete">删除</el-button>
    </div>
    <div class="table">
      <el-table
        :height="'calc(' + tableHeight + 'px)'"
        border
        :data="tableData"
        ref="tableref"
        @selection-change="handleSelectionChange"
        v-loading="loadingPage"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <!--<el-table-column min-width="150" prop="id" label="管理员编号" show-overflow-tooltip></el-table-column>-->
        <el-table-column
          min-width="70"
          prop="status_label"
          label="状态"
        ></el-table-column>
        <el-table-column
          min-width="150"
          prop="plan_name"
          label="任务名称"
        ></el-table-column>
        <el-table-column min-width="150" prop="begin_datetime" label="评测开始时间">
        </el-table-column>
        <!-- <el-table-column min-width="150" prop="end_datetime" label="评测结束时间">
        </el-table-column> -->
        <!--<el-table-column min-width="100" prop="name" label="显示排序"></el-table-column>-->
        <el-table-column min-width="100" prop="product_name" label="评测方案">
        </el-table-column>
        <el-table-column min-width="350" prop="deptName"  label="所属部门">
          <template #default="scope">
            <el-cascader
            style="width: 100%"
            v-model="scope.row.dept"
            :options="options"
            :props="props"
            disabled
          ></el-cascader>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="120">
          <template #header>
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div>操作</div>
              <div @click="setFull">
                <el-tooltip content="全屏" placement="bottom">
                  <el-icon><full-screen /></el-icon>
                </el-tooltip>
              </div>
            </div>
          </template>
          <template #default="scope">
            <span
              class="table-operate-btn"
              v-if="scope.row.status !== '3'"
              @click="handleEdit(scope.row, 'edit')"
              >编辑</span
            >
            <span
              class="table-operate-btn"
              @click="handleEdit(scope.row, 'delete')"
              >删除</span
            >
            <span
              class="table-operate-btn"
              @click="handleEdit(scope.row, 'status')"
              v-if="scope.row.status !== '3'"
              >完成任务</span
            >
            <!-- <span
              class="table-operate-btn"
              @click="handleEdit(scope.row, 'delete')"
              v-show="isShowBtn('adminManage', '管理员管理', 'Delete')"
              >通知家长</span
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      v-bind:child-msg="pageparm"
      @callFather="callFather"
    ></Pagination>
    <addstudentsGroup
      :optionss="options"
      :productsgetlist="productsgetlist"
      ref="addstudentsGroupflag"
      @refreshData="getData"
    ></addstudentsGroup>
  </div>
</template>
<script>

import addstudentsGroup from "./components/addstudentsGroup";
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import { groupget, groupdelete, deptproduct, UsersUsers, options, groupput, deptSchoolPeo } from '@/api/api'
export default {
  components: {
    Pagination,
    addstudentsGroup
  },
  name: 'studentsGroup',
  data() {
    return {
      optionschool:[],
      dateList:[],
      props: { multiple: true,value:'id', label:'name'},
      options: [],
      objArrDetle: [], 
      userlist: [],// pingce
      productsgetlist: [], // fangam
      isFull: false,
      visible: false,
      tableHeight: 500,
      loadingPage: false,
      formInline: {
        status: '',
        product: '',
        name: '',
        dept_id:'',
        page: 1,
        limit: 10,
      },
      pageparm: {
        page: 1,
        limit: 10,
        total: 0
      },
      statusList: [],
      timers: [],
      tableData: []
    }
  },
  methods: {
      getParentValues(options, targetValue) {
      for (const option of options) {
        if (option.id === targetValue) {
          return [option.id];
        }
        if (option.children) {
          const parentValues = this.getParentValues(option.children, targetValue);
          if (parentValues) {
            return [option.id, ...parentValues];
          }
        }
      }
      return null;
    },
    handlecascader(e) {
      let arr = []
      e.forEach(i => { 
        arr.push(i[i.length-1])
      })
      this.formInline.dept_id = arr.join(',')
     },
    handlerPicker(e) { 
      // console.log(e)
      if (e) {
        this.formInline.begin_date = e[0]
        this.formInline.end_date = e[1]
      } else { 
         this.formInline.begin_date =''
        this.formInline.end_date =''
      }
    },
    async getSchoolData() {
     await deptSchoolPeo({limit: 999,page: 1
      }).then(res => {
        // console.log(res.data)
        this.options = res.data.data
      })
    },
    handleDelteAll() {
      if (this.objArrDetle.length) {
        let vm = this
        vm.$confirm('您确定要删除选中的内容么？', {
          closeOnClickModal: false
        }).then(res => {
          groupdelete({ id: this.objArrDetle.join(',') }).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.search()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      }

    },
    handleSelectionChange(e) {
      this.objArrDetle = []
      e.forEach(i => {
        this.objArrDetle.push(i.id)
      })
      // // console.log(e)
    },
    getoptions() {
      options({ parent__isnull: true, title: '任务状态' }).then(res => {
        this.statusList = res.data.data[0].children
      })
    },
    // 表格序列号
    getIndex($index) {
      // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
      return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
    },
    setFull() {
      this.isFull = !this.isFull
      window.dispatchEvent(new Event('resize'))
    },
    addAdmin() {
      this.$refs.addstudentsGroupflag.addAdminFn(null, '新增')
    },
    handleEdit(row, flag) {
      if (flag == 'edit') {
        this.$refs.addstudentsGroupflag.addAdminFn(row, '编辑')
      }
      else if (flag == 'delete') {
        let vm = this
        vm.$confirm('您确定要删除选中的任务么？', {
          closeOnClickModal: false
        }).then(res => {
          groupdelete(row).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.search()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      } else if (flag == 'status') {
        let vm = this
        vm.$confirm('您确定要完成选中的任务么？', {
          closeOnClickModal: false
        }).then(res => {
          row['status'] = '3'
          groupput(row).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.search()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      }
      else if (flag == "reset") {
        this.optionschool =[]
        this.formInline = {
       status: '',
        product: '',
        name: '',
        dept_id:'',
        page: 1,
        limit: 10,
        }
        this.pageparm = {
          page: 1,
          limit: 10,
          total: 0
        }
        this.dateList = []
        this.getData()
      }
    },

    callFather(parm) {
      this.formInline.page = parm.page
      this.formInline.limit = parm.limit
      this.getData()
    },
    search() {
      this.formInline.page = 1
      this.formInline.limit = 10
      this.getData()
    },
    // async getDataUsersUsers() {

      // UsersUsers({ page: 1, limit: 999 }).then(res => {
      //   this.loadingPage = false
      //   if (res.code == 2000) {
      //     this.userlist = res.data.data
      //   }
      // })
    // },
    async getDataproductsget() {
      deptproduct({ page: 1, limit: 999 }).then(res => {
        if (res.code == 2000) {
          this.productsgetlist = res.data.data
        }
      })
    },
    //获取列表
    async getData() {
      this.loadingPage = true
      groupget(this.formInline).then(res => {
        this.loadingPage = false
        if (res.code == 2000) {
          this.tableData = res.data.data

          this.pageparm.page = res.data.page;
          this.pageparm.limit = res.data.limit;
          this.pageparm.total = res.data.total;

        } else { 
          
          this.$message.warning(res.msg)
        }
      })
    },

    timeChange(val) {
      if (val) {
        this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    // 计算搜索栏的高度
    listenResize() {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight() {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight + 50 : 0
      tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight = getTableHeight(tabSelectHeight)
    }
  },
async  created() {
   await this.getSchoolData()
    this.getData()
    // this.getDataUsersUsers()
    this.getDataproductsget()

    this.getoptions()
  },
  mounted() {
    // 监听页面宽度变化搜索框的高度
    window.addEventListener('resize', this.listenResize);
    this.$nextTick(() => {
      this.getTheTableHeight()
    })
  },
  unmounted() {
    // 页面销毁，去掉监听事件
    window.removeEventListener("resize", this.listenResize);
  },
}
</script>
<style scoped>
/deep/.el-tag{
  margin-left: 10px !important;
}
</style>
<style>
.appdownload {
  display: flex;
  justify-content: flex-end;
}
</style>
