<template>
  <div class="htmlreportView" id="htmlreportView">
    <div class="stylePdf" @click="pdferport" v-if="pdferportFlag">导出pdf</div>
    <div id="pdfHtmlQingDao" class="reportView">
      <div class="card-title"> {{ planData.info.report_title }}</div>
      <div class="headerTitle-actionsno">
        阅读本报告时，您应知悉
        <a class="axuzhi" @click="scrollToSection('xuzhi')">《附：阅读需知》</a
        >中内容，正确解读和使用本报告内容。
      </div>
      <div class="headerTitle">一、基本信息</div>
      <div class="headerTitle-actions">
        {{ planData.info.name}}，应测人数{{planData.info.plan_users_count}}人，实测人数{{planData.info.real_users_count}}人，实测人数占比{{planData.info.real_user_percent}}%，实测学生中男生{{planData.info.user_male_count}}人，占比{{planData.info.user_male_percent}}%，女生{{planData.info.user_female_count}}人，占比{{planData.info.user_female_percent}}%。
      </div>
          
      <div class="headerTitle-actions">
        测评报告中可信程度等级低🌟（可信程度最低一颗星，最高三颗星）的同学人数{{planData.info.kexin_user_low_count}}人，占比{{planData.info.kexin_user_low_percent}}%。
         
      </div>

      <div class="headerTitle">二、总体情况</div>
      <div class="headerTitle-actions">
        本次测评的风险等级为四类：发展良好、需要关心、重点关怀、亟待关护。等级划分的依据主要是抑郁、焦虑、情绪稳定性、人际关系、心理资源等。
      </div>
      <div class="headerTitle-actions">基于本次心理健康测评，本校同学中，</div>
     
      <div class="headerTitle-actions">亟待关护的学生人数为{{planData.info.level_4_count}}，其中：</div>
      <div class="headerTitle-actions" style="text-indent: 4rem">
        <div v-for="(item,index) in planData.data" :key="index">
            {{ item.name }}的总人数有 {{ item.level_4_count}} 人
             <div class="headerTitle-actions" v-for="(jtem,jndex) in item.data" :key="jndex" style="text-indent: 6rem">
                  {{ jtem.name }}的总人数有 {{ jtem.level_4_count}} 人
            </div>
        </div>
      </div>
      <div class="headerTitle-actions">重点关怀的学生人数为{{planData.info.level_3_count}}，其中：</div>
      <div class="headerTitle-actions" style="text-indent: 4rem">
   <div v-for="(item,index) in planData.data" :key="index">
            {{ item.name }}的总人数有 {{ item.level_3_count}} 人
             <div class="headerTitle-actions" v-for="(jtem,jndex) in item.data" :key="jndex" style="text-indent: 6rem">
                  {{ jtem.name }}的总人数有 {{ jtem.level_3_count}} 人
            </div>
        </div>
      </div>
      <div class="headerTitle-actions">需要关心的学生人数为{{planData.info.level_2_count}}，其中：</div>
      <div class="headerTitle-actions" style="text-indent: 4rem">
   <div v-for="(item,index) in planData.data" :key="index">
            {{ item.name }}的总人数有 {{ item.level_2_count}} 人
             <div class="headerTitle-actions" v-for="(jtem,jndex) in item.data" :key="jndex" style="text-indent: 6rem">
                  {{ jtem.name }}的总人数有 {{ jtem.level_2_count}} 人
            </div>
        </div>
      </div>
      <div class="headerTitle-actions">其余同学发展良好。</div>
      <div class="headerTitle-actions">
        对于名单中那些值得关护的学生，请在保持关注的同时，不必过分紧张或者担心。每一个学生的成长过程中，重要的不仅是学业成绩，和学业成绩相比身心健康对其长远发展至关重要。成年人需要以更全面的眼光来看待学生，关注其情绪状态、交友状况、内心感受等。
      </div>
      <div class="headerTitle-actions">
        每一个学生都是一粒种子，对待每一粒种子我们都需要耐心。每位老师都是一个小小的太阳，来自老师的支持与鼓励是这粒种子发芽并茁壮成长的最佳养料。
      </div>
      <div class="headerTitle">三、报告结果</div>
      <div class="headerTitle">焦虑</div>
      <div class="headerTitle-actions" v-for="(item,index) in planData.data" :key="index">
          <div class="headerTitle-actions">
            {{item.name}}学生焦虑均分为{{item.module_jiaolv_mean}}，班级学生焦虑情况如下：
               <div style="text-indent: 4rem" v-for="(jte,jndex) in item.data" :key="jndex" class="headerTitle-actions">{{jte.name}}：均分为{{jte.module_jiaolv_mean}}</div>
          </div>
       
       

      </div>

      <div class="headerTitle">抑郁</div>
      <div class="headerTitle-actions" v-for="(item,index) in planData.data" :key="index">
          <div class="headerTitle-actions">
            {{item.name}}学生抑郁均分为{{item.module_yiyu_mean}}， 班级学生抑郁情况如下：    
               <div style="text-indent: 4rem" v-for="(jte,jndex) in item.data" :key="jndex" class="headerTitle-actions">{{jte.name}}：均分为{{jte.module_yiyu_mean}}</div>
          </div>
       
       

      </div>
      <div class="headerTitle">情绪稳定性</div>
      <div class="headerTitle-actions" v-for="(item,index) in planData.data" :key="index">
          <div class="headerTitle-actions">
            {{item.name}}学生情绪稳定性均分为{{item.module_qingxu_mean}}，班级学生情绪稳定性情况如下：
       
               <div style="text-indent: 4rem" v-for="(jte,jndex) in item.data" :key="jndex" class="headerTitle-actions">{{jte.name}}：均分为{{jte.module_qingxu_mean}}</div>
          </div>
       
       

      </div>
      <div class="headerTitle">人际困扰</div>
      <div class="headerTitle-actions" v-for="(item,index) in planData.data" :key="index">
          <div class="headerTitle-actions">
            {{item.name}}学生人际困扰均分为{{item.module_renji_mean}}，班级学生人际困扰情况如下：
         
               <div style="text-indent: 4rem" v-for="(jte,jndex) in item.data" :key="jndex" class="headerTitle-actions">{{jte.name}}：均分为{{jte.module_renji_mean}}</div>
          </div>
       
       

      </div>
      <div class="headerTitle">内外向</div>
      <div class="headerTitle-actions" v-for="(item,index) in planData.data" :key="index">
          <div class="headerTitle-actions">
            {{item.name}}学生内外向均分为{{item.module_neiwai_mean}}，班级学生内外向情况如下：

               <div style="text-indent: 4rem" v-for="(jte,jndex) in item.data" :key="jndex" class="headerTitle-actions">{{jte.name}}：均分为{{jte.module_neiwai_mean}}</div>
          </div>
       
       

      </div>
      <div class="headerTitle">应对方式</div>
      <div class="headerTitle-actions" v-for="(item,index) in planData.data" :key="index">
          <div class="headerTitle-actions">
            {{item.name}}学生问题解决取向应对方式均分为{{item.module_ja_mean}}，班级学生问题解决取向应对方式情况如下：
     
               <div style="text-indent: 4rem" v-for="(jte,jndex) in item.data" :key="jndex" class="headerTitle-actions">{{jte.name}}：均分为{{jte.module_ja_mean}}</div>
          </div>
      </div>
      <div class="headerTitle-actions" v-for="(item,index) in planData.data" :key="index">
          <div class="headerTitle-actions">
            {{item.name}}学生情绪启动取向均分为{{item.module_jb_mean}}，班级学生情绪启动取向情况如下：
     
               <div style="text-indent: 4rem" v-for="(jte,jndex) in item.data" :key="jndex" class="headerTitle-actions">{{jte.name}}：均分为{{jte.module_jb_mean}}</div>
      </div>
      </div>
      <div class="headerTitle">心理资本</div>
      <div class="headerTitle-actions" v-for="(item,index) in planData.data" :key="index">
          <div class="headerTitle-actions">
            {{item.name}}学生心理资本均分为{{item.module_ziben_mean}}，班级学生心理资本情况如下：
           
               <div style="text-indent: 4rem" v-for="(jte,jndex) in item.data" :key="jndex" class="headerTitle-actions">{{jte.name}}：均分为{{jte.module_ziben_mean}}</div>
          </div>
       
       

      </div>
      <div class="headerTitle">自我价值感</div>
      <div class="headerTitle-actions" v-for="(item,index) in planData.data" :key="index">
          <div class="headerTitle-actions">
            {{item.name}}学生自我价值感均分为{{item.module_jiazhi_mean}}，班级学生自我价值感情况如下：
             
               <div style="text-indent: 4rem" v-for="(jte,jndex) in item.data" :key="jndex" class="headerTitle-actions">{{jte.name}}：均分为{{jte.module_jiazhi_mean}}</div>
          </div>
       
       

      </div>
      <div class="headerTitle">四、改善建议</div>
      <div class="headerTitle-actionsno">焦虑</div>
      <div class="headerTitle-actions">
        现代生活中，焦虑彷佛已经成为我们的公敌。然而，焦虑和焦虑症不一样，每个人的生活中都有感到焦虑的时候。焦虑也未必是坏事。在心理学家眼里，焦虑是面对潜在挑战或威胁时大脑给我们发出的信号。在人类进化的过程中，正是恼人的焦虑帮助我们更敏锐地识别出潜在的威胁，并为应对威胁做好准备。
      </div>
      <div class="headerTitle-actions">
        适当焦虑是有用的，有利于提前做好准备，提高学习和工作效率。然而，长期过度焦虑，会损害身体和心理健康，甚至可能发展成焦虑症这样的心理障碍。
      </div>
      <div class="headerTitle-actions" style="font-weight: 700">建议</div>
      <div class="headerTitle-actions">
        学校的心理老师有着丰富的资源，可以让学生们学习正念练习，有效缓解焦虑。
      </div>
      <div class="headerTitle-actions">
        心理学领域的知名期刊《临床心理学年鉴》总结到，长期的正念练习对于改善焦虑的效果可以媲美专业的心理咨询。正念练习可以减低杏仁核的活跃程度，杏仁核脑区和情绪的诞生有关，当它活跃的时候，会让人产生害怕、恐惧的情绪。
      </div>
      <div class="headerTitle-actions">
        正念要求我们把注意力放在此时此刻的感受上，不对这些感受进行评价。正念练习过程中也可能会出现焦虑。请记住我们不必去排斥出现的焦虑情绪，而是去接纳它存在，去直面它，把它当成脑海里飘过的思绪。
      </div>
      <div class="headerTitle-actions">
        在不断的练习后，我们可以慢慢学会和焦虑共处，共处少受其影响。
      </div>
      <div class="headerTitle">抑郁</div>
      <div class="headerTitle-actions">
        您可能会觉得抑郁就是悲伤、独自流泪这类情绪低落的状况。的确，情绪低落是抑郁的一个特征，但这样理解抑郁是不够全面。
      </div>
      <div class="headerTitle-actions">
        抑郁更隐蔽的特征，是快乐感受降低，精力丧失，认知功能下降，看不到希望等。处于抑郁状态的人，心里可能出现一些不健康的声音，比如“再怎么努力都是徒劳”，“一切都没意思”，“我不行”，“我看不到希望”……他们比平时更容易疲惫，饮食睡眠的规律可能也会发生变化。
      </div>
      <div class="headerTitle-actions">
        周围的人可能难以觉察出他们的痛苦，可能会误以为他们变懒了。请记住，他们并不是真的懒惰——抑郁是伴随着神经递质和激素变化的。您也许觉得他无法做到其他人眼中简单的事，哪怕不理解，也不能指责或嘲笑。
      </div>
      <div class="headerTitle-actions">
        每个人的生活中，都会面临压力，每个人都或多或少体验过抑郁情绪。然而，普通人体会到的抑郁情绪和临床意义上的抑郁症大不同。
      </div>
      <div class="headerTitle-actions">
        即便是罹患抑郁症，医学和心理学也是可以提供帮助的。
      </div>
      <div class="headerTitle-actions" style="font-weight: 700">建议</div>

      <div class="headerTitle-actions">
        如果您认为有必要，可以和学校的心理老师讨论具体的方案。
      </div>
      <div class="headerTitle-actions">
        我们建议您保证本校学生体育锻炼的时间。适量的运动不仅有利于身体健康，还能够有效应对不良情绪。
      </div>
      <div class="headerTitle">情绪稳定性</div>
      <div class="headerTitle-actions">
        情绪稳定性可以理解成一个人对生活中的消极信息是否敏感。作为一种独特的人格倾向，无论情绪稳定性是高是低，都有各自的力量。
      </div>
      <div class="headerTitle-actions">
        情绪稳定性较高的人，对消极信息不那么敏感。产生的感受也不特别强烈，比较容易恢复平静。他们能够把更多的时间和精力放在自己感兴趣的事物上，而不需要花大量的时间来处理自己的情绪困扰。
      </div>
      <div class="headerTitle-actions">
        情绪稳定性较低的人，对消极信息敏感，更容易体验到强烈的消极情绪。对威胁性的信息更敏感，其实能提醒我们对之进行有效规避，并找到适合自己的生存空间。他们还能将丰富的情绪体验通过各种艺术形式传递出来。
      </div>
      <div class="headerTitle-actions">每个人都有情绪不稳定的时候。</div>
      <div class="headerTitle-actions" style="font-weight: 700">建议</div>
      <div class="headerTitle-actions">1、带领学生学习深呼吸</div>
      <div class="headerTitle-actions">
        深呼吸能够唤醒我们的副交感系统，让我们感到放松，让身心重聚能量。
      </div>
      <div class="headerTitle-actions">
        深呼吸简单有效，但大多数人都需要学习才能掌握深呼吸的诀窍。深呼吸的步骤可以分成三步：
      </div>
      <div class="headerTitle-actions">
        第一、吸气。用腹部深沉而缓慢地吸一口气。去感受空气进入身体之后，腹部慢慢鼓起来的感觉，去感受它是如何变大、变胀，并最终被空气填满。
      </div>
      <div class="headerTitle-actions">
        第二、憋气。感受腹部此时此刻的感觉，持续1- 4 秒都可以。
      </div>
      <div class="headerTitle-actions">
        第三、呼气。慢慢地把气呼出来，呼气的时间要和吸气的时间差不多长。呼气时，用鼻翼去感受温热的空气流过的感觉。
      </div>
      <div class="headerTitle-actions">
        耐心重复这个步骤五分钟，您就能够体会到神奇的效果。
      </div>
      <div class="headerTitle-actions">
        2、可以鼓励学生们写笔记，把自己的感受写出来。
      </div>
      <div class="headerTitle">人际困扰</div>
      <div class="headerTitle-actions">
        2017年，教育部等十一个部门联合印发《加强中小学生欺凌综合治理方案》，该方案指出中小学生欺凌是发生在中小学校和中等职业学校内外、学生之间，一方个体或群体单次或多次蓄意或恶意通过肢体、语言及网络等手段实施欺凌、侮辱，造成另一方个体或群体身体伤害、财产损失或精神损害等的事件。
      </div>

      <div class="headerTitle-actions">
        校园欺凌行为有很多种，我们熟知的一些欺凌行为，如打、踢、抢夺他人财物，属于身体欺凌。您可知道，还有另外两类常见的欺凌：一类是言语欺凌，如威胁、取笑或取侮辱性的外号等；另一类是关系欺凌，以伤害他人的社交关系为目标，例如排挤、散步谣言等。许多研究表明，欺凌不仅对被欺凌者的身心健康造成伤害，对欺凌者的发展同样有不良的影响。
      </div>
      <div class="headerTitle-actions">
        欺凌并不隐蔽，大多数欺凌并不是发生在校园里安静无人的角落。当欺凌发生时，旁观者的做法特别重要。在学校里，当欺凌发生时如果有个旁观者能够说一声“嘿，XXX欺凌者的名字，您在干什么”，这个简单的举动就可能有效制止欺凌行为。在学校外，当知道有同学被欺凌的时候，学生需要知道要立刻向学校和老师报告。这种行为不是“打小报告”，不仅是在帮助被欺凌者，也帮助欺凌者不要在这条黑暗的路上越走越远。
      </div>
      <div class="headerTitle-actions">
        欺凌之所以隐蔽，主要是学校、老师和家长往往看不到这样的行为。
      </div>
      <div class="headerTitle-actions">
        欺凌者未必是学业不良的少年，有可能是学业表现优异的同学。有时候可能他们也意识不到自己的行为是欺凌。
      </div>
      <div class="headerTitle-actions">
        被欺凌者的心理是一个很值得学校和教师关注的现象。不少被欺凌者会从自己身上找原因，比如认为自己之所以被欺凌是因为自己不招人喜欢，不够强壮，人际关系差，或者是自己不小心招惹谁了。他们也并不是不想求助，之所以选择性地不向学校和老师报告自己的状况，是因为没有百分之百的信心，认为学校和老师会帮助自己。在帮助被欺凌学生的过程中，需要给予百分之百确定的信息，被欺凌不是他的错，错误在于欺凌者。
      </div>
      <div class="headerTitle-actions" style="font-weight: 700">建议</div>
      <div class="headerTitle-actions">1、创造安全的学校氛围</div>
      <div class="headerTitle-actions">
        对学校领导来说，我们鼓励您不去假设本校没有欺凌现象，不存在欺凌者或者被欺凌者，而是去假设这种行为存在或者很有可能会存在。需要在学校层面上明确强调对于欺凌行为零容忍。为了防止或者应对欺凌，鼓励各班开展主题班会是很好的形式。可以让班主任或者心理老师和同学们交流什么是欺凌；明确讨论当欺凌发生的时候，在不同的情境中被欺凌者和旁观者应该怎么做。
      </div>
      <div class="headerTitle-actions">
        2、多开展集体活动，鼓励同学们多为集体出力
      </div>
      <div class="headerTitle-actions">
        同学们一起参加一次激烈的球赛，或者一起去户外徒步，共同拼搏或者一同跋山涉水的经历也能拉近同学们之间的距离，增进同学们之间的相互了解。活动中应该更加重视平等的参与机会，比赛的名次其实不那么重要。鼓励同学多去看彼此的优点，同时也不要过分担心自己的缺点和不足，毕竟每个人都有缺点。
      </div>

      <div class="headerTitle">内外向</div>
      <div class="headerTitle-actions">
        内向者对社交的需求不太高，太多的社交会让内向者感到劳累，消耗能量。他们需要的外部刺激不多，能享受独处的时光。对他们来说，独处看小说、刷剧、玩游戏甚至是独自发呆都是更好的放松方式。内向的人也需要朋友，但是内向的人喜欢交几个关系比较密切的朋友。
      </div>
      <div class="headerTitle-actions">
        外向者从社交中获得快乐与能量，愿意花时间参与社交活动。对他们来说，和他人一起才是最好的放松方式，长时间独处会让他们感觉不自在。外向的人一般朋友的数量会比较多。
      </div>
      <div class="headerTitle-actions">
        内外向是一个连续的谱系，绝大多数人处在中间状态，一些人会更偏向某一端。内外向的稳定性是相对的，在成长过程中是可以多多少少改变的。
      </div>
      <div class="headerTitle-actions" style="font-weight: 700">建议</div>
      <div class="headerTitle-actions">内向和外向，都是宝贵的人格特征</div>
      <div class="headerTitle-actions">
        外向的人爱说话，爱交际，喜欢和人在一起，并不是肤浅的表现。这些刻板印象其实是对外向的误解。外向的人完全可以同时拥有深刻、体贴和善于沟通的特质。
      </div>
      <div class="headerTitle-actions">
        内向者对自己的内心世界更敏感，他们更善于利用独处的时光，更有机会发挥艺术创造力和想象力，挖掘自己更丰富的内心。内向并不等于害羞或不善言辞，言语表达是一种技能，可能通过多参与人际交往提高。
      </div>
      <div class="headerTitle">应对方式</div>
      <div class="headerTitle-actions">
        应对方式是个体适应环境所采用方法或策略，有一定的跨情景一致性，同时也对心身健康有一定的影响。每个人的应对方式都自己的特点，涉及心理层面如对事件进行再评价、行为层面如回避特定的场景和生理层面如提醒自己放松。还可以把应对方式分成问题解决取向和情绪启动取向，二者有各自适用的场景。
      </div>
      <div class="headerTitle-actions" style="font-weight: 700">建议：</div>
      <div class="headerTitle-actions">1、鼓励同学们形成应对方式集</div>
      <div class="headerTitle-actions">
        每面临或者处理完一次事件时，鼓励学生们将处理过程中自己的应对方式记录下来，这样可以很好地认识到自己常用的应对方式有哪些，自己能应用得很好的应对方式又有哪些。
      </div>
      <div class="headerTitle-actions">2、提供学习的机会</div>
      <div class="headerTitle-actions">
        鼓励学生们遇到困难的时候可以来找老师，老师愿意帮助他们。
      </div>
      <div class="headerTitle">心理资本</div>
      <div class="headerTitle-actions">
        心理资本主要反映个体能够获得的社会支持。当个体有需要时，能否得到来自于他人的同情以及资源，从而使得个体的问题得到解决。
      </div>
      <div class="headerTitle-actions">
        社会支持可以分为情感支持、信息支持、陪伴支持及工具性支持。一些社会支持是客观、可见且实际的，而另一些则更加主观，表现为体验到的支持。
      </div>
      <div class="headerTitle-actions" style="font-weight: 700">建议</div>
      <div class="headerTitle-actions">1、多和家人沟通</div>

      <div class="headerTitle-actionsno">
        有时候我们会觉得和父母沟通不那么容易，但是与家人保持畅通的交流还是重要的。如果爸爸妈妈比较喜欢打断你说话，请耐心地请求他们尽量不要打断你。你还可以告诉他们，尽量不要给出批评或者评价，你只是想收获一些建议。
      </div>
      <div class="headerTitle-actions">2、多参加集体活动</div>
      <div class="headerTitle-actions">
        集体活动是让同学和老师全面了解你，也让你充分了解同学和老师的好机会。人们往往更愿意对自己了解的人施以援手。集体活动也能让你和同学们有更多的共同话题。
      </div>
      <div class="headerTitle">自我价值感</div>
      <div class="headerTitle-actions">
        自我价值感是心理健康的重要指标之一，表明个体在多大程度上相信自己是有能力的、重要的和有价值的。
      </div>
      <div class="headerTitle-actions">
        一般而言，自我价值感比较强的人比较能够接纳自己，也更为愉快和健康，具有更好的身心调节能力；反之，自我价值感低的人对自我的接纳程度不高。自我价值感对青少年的发展非常重要。一方面，自我价值感的发展状况与个体的心理健康直接相联系；另一方面，自我价值感也会间接对个体的认知、动机、情感和社会行为产生影响。
      </div>
      <div class="headerTitle-actions">
        自我价值感是一个主观的感受，常常会脱离真实的状况。其实许多自我价值感偏低的人，并不是因为自己真的没有价值，而是因为没有看到自己的长处。
      </div>

      <div class="headerTitle-actions">
        每个人，都与众不同，都有自己独特的价值。
      </div>
      <div class="headerTitle-actions" style="font-weight: 700">建议</div>
      <div class="headerTitle-actions">1、树立“成长型”思维模式</div>
      <div class="headerTitle-actions">
        成长是一个缓慢，但是每天都在进行的事情。成长过程中，最重要的密码是坚持。去持之以恒地做一件事情。当然，做事情的态度也很重要，要怀着“学习”的心态，而不是“证明自己”的心态。不仅对待日常的学习是如此，对待日常生活中的每一件事情都可以这样去考虑。
      </div>
      <div class="headerTitle-actions">2、建立良好的行为习惯</div>
      <div class="headerTitle-actions">
        培养健康良好的行为习惯，有助于提升自我价值。合理的饮食，规律的睡眠和体育锻炼，对于提升自我价值感也有帮助。
      </div>
      <div id="xuzhi">
        <div class="headerTitle">
          附：阅读需知
        </div>
        <div class="headerTitle-actionsno">
          您在阅读报告时需要注意以下几点：
        </div>

        <div class="headerTitle-actions">
          1、本次测评结果只反应学生受测时的状态，请辩证性看待测评结果。
        </div>
        <div class="headerTitle-actions">
          2、若本次测评结果与您平时对学生的了解有偏离，不必过分担心。任何一次心理测评的结果会受到作答者答题时的心理状态、施测环境、施测流程等多方面因素的影响，具体请以实际情况为准。
        </div>
        <div class="headerTitle-actions">
          3、请不要根据本次测评结果给学生贴标签，需要用发展的眼光来看待学生，发展意味着变化。
        </div>
        <div class="headerTitle-actions">
          4、本次测评结果中的风险等级水平、情绪和人际困扰不能作为医学临床诊断的依据。
        </div>
        <div class="headerTitle-actions">
          5、文中推荐建议的小提示是普适性的，具体需要以班级和学生的实际情况进行个性化实操。
        </div>
        <div class="headerTitle-actions">
          6、请不要将该报告和学校任何工作人员的奖惩联系在一起。
        </div>
        <div class="headerTitle-actions">
          7、不宜将不同年级的状况进行对比，有些年级本身的压力可能较其他年级大一些。
        </div>
        <div class="headerTitle-actions">
          心理测评和物理测评不一样，测量误差在所难免。除此之外，心理测查结果的可靠性，部分取决于受测者当时的状态。为了发挥本次心理测评的结果的指导性，我们将这份结果呈现给您。基于心理测评的特点，结果很可能会和真实情况有偏差，甚至很可能和您平时的印象也不甚一致。
        </div>
        <div class="headerTitle-actions">
          <span style="color: red"
            >为了降低测评结果被误用的机会，心理测评的结果不宜公开。</span
          >
          建议您将本报告结果查阅人员限定为您、学校相关的教学管理人员和心理老师。
        </div>
      </div>
      <!-- <footer class="footer">
        <p>Copyright © 2024 中科心馨社会心理服务平台 - All right reserved</p>
      </footer> -->
    </div>
  </div>
</template>
<style scoped>
.axuzhi {
  cursor: pointer;
  color: blue;
}
.fujian5 {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 6% 0px;
}
.fujian5-3 {
  position: absolute;
  width: 30px;
  top: 20%;
  left: 20%;
}
.fujian5-1 {
  width: 100%;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    to right,
    rgba(72, 116, 203, 0.1),
    rgba(72, 116, 203, 1)
  );
}
.fujian5-1copy {
  width: 20px;
  height: 400px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    to bottom,
    rgba(72, 116, 203, 0.1),
    rgba(72, 116, 203, 1)
  );
}
.fujian5-2copy {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
  position: absolute;
  top: 50%;
  left: 61%;
  transform: translate(-50%, -50%);
}
.fujian5-2 {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.fujian5-2-1 {
  line-height: 2;
}
.fujian4 {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 20px 10%;
  position: relative;
  margin: 5% 0;
}
.fujian4-3 {
  position: absolute;
  width: 30px;
  top: 0vh;
  left: 20%;
}
.fujian4-1 {
  width: 100%;
  height: 20px;
  background: linear-gradient(
    to right,
    rgba(72, 116, 203, 0.1),
    rgba(72, 116, 203, 1)
  );
}
.fujian4-2 {
  display: flex;
  justify-content: space-between;
}
.fujian4-2-1 {
  line-height: 2;
}
.fujian3 {
  width: 90%;
  padding: 10%;
  height: 15px;
  display: flex;
}
.fujian3-1 {
  flex: 1;
  text-align: center;
  position: relative;
}
.fujian3-1-1 {
  position: relative;
  top: 2vh;
}
.fujian3-1-1img {
  position: absolute;
  top: -3vh;
  left: 39%;
  width: 30px;
}
.headerTitle-actionsno {
  line-height: 1.8;
  font-size: 1.3rem;
  width: 100%;
}
.headerTitle-actions {
  text-indent: 2rem;
  line-height: 1.8;
  font-size: 1.3rem;
  width: 100%;
}
.headerTitle {
  font-size: 1.5rem;
  padding: 2rem 0;
  font-weight: 700;
  width: 100%;
}
.stylePdf {
  cursor: pointer;
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
}
.footer {
  display: grid;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.htmlreportView {
  width: 100%;
  height: 100%;
}
.reportView {
  padding: 0 18%;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
}
.card-title {
  font-size: 2rem;
  line-height: 2;
  font-weight: bold;
}
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.card-actions {
  font-size: 1.5rem;
  line-height: 2;
}
</style>
<script>
// 引入水印文件地址
import watermark from '@/utils/watermark.js'
import { downloadPDF } from "@/utils/pdf.js"  //工具方法，导出操作
import { getStore, removeStore } from "@/utils/util";
import { getreportViewinfo } from '@/api/api'
   import {useMutitabsStore} from "@/store/mutitabs";
export default {
            setup(){
            const mutitabsstore = useMutitabsStore()
            return { mutitabsstore}
        },
  name: 'reportView',
  data() {
    return {
      pdferportFlag:true,
      planData: {
        info: {},
        data:[]
      },
      
      fujian3: [{
        name: '发展良好',
        color: 'rgb(46,84,161)'
      }, {
        name: '需要关心',
        color: 'rgb(88,182,49)'
      }, {
        name: '重点关怀',
        color: 'rgb(255,192,0)'
      }, {
        name: '亟待关护',
        color: 'rgb(192,0,0)'
      }],
      headerTitle: '一、基本信息',
      headerTitle2: '二、家长须知',
      headerTitle3: '三、总体情况',
      headerTitle4: '四、各项详情',

    }
  },
  created() {
    this.planData = JSON.parse(getStore('reportViewAnwerData'))
    console.log(this.planData)
    this.planData.info.level_2_count = 0;
    this.planData.info.level_3_count = 0;
    this.planData.info.level_4_count = 0;
    this.planData.data.forEach(item => { 
     this.planData.info.level_2_count = this.planData.info.level_2_count + item.level_2_count
     this.planData.info.level_3_count = this.planData.info.level_3_count + item.level_3_count
     this.planData.info.level_4_count = this.planData.info.level_4_count + item.level_4_count
    })
    // for (let key in data) { 
    //   Object.assign(this.planData,data[key])
    // }
        this.$nextTick(() => { 
watermark.set(this.mutitabsstore.userName,document.getElementById('#htmlreportView'))
    })



  },
  methods: {
    getData() {
      getreportViewinfo({ id: this.planId }).then(res => {
        console.log(res)
        this.planData = res
      })
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
    pdferport() {
      this.pdferportFlag = false
      this.$nextTick(() => {
        downloadPDF({
          id: "app",
          fileName: `报告.pdf`,
          textBreakClassName: "roadMonitoringBreakClass",
          isDownLoad: true,
          isNeedFooter: false,
        });
        setTimeout(() => { 
          this.pdferportFlag = true
        },1000)
      })
    }
  }
}
</script>
