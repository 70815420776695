import config from "@/config"
import LIBCONFIG from 'LIBCONFIG'
// create an axios instance

export const domain = LIBCONFIG.API_DOMAIN ? LIBCONFIG.API_DOMAIN : config.API_DOMAIN;
export const url = LIBCONFIG.API_URL ? LIBCONFIG.API_URL : config.API_URL;
export const url_ = LIBCONFIG.API_URL_ ? LIBCONFIG.API_URL_ : config.API_URL_;
export const url_other = LIBCONFIG.APIOTHER ? LIBCONFIG.APIOTHER : config.APIOTHER

// console.log(domain, LIBCONFIG.API_URL , 121212)
