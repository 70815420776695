<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="first">
        <el-form
          :inline="false"
          :model="formData"
          ref="rulesForm"
          label-position="right"
          label-width="130px"
        >
          <el-form-item label="用户：" prop="username">
            {{ formData.username }}
          </el-form-item>
          <el-form-item label="评估状态：" prop="evaluate_status_label">
            {{ formData.evaluate_status_label }}
          </el-form-item>
          <el-form-item label="预警级别：" prop="warning_level_label">
            {{ formData.warning_level_label }}
          </el-form-item>
          <el-form-item label="预警原因：" prop="description">
            {{ formData.description }}
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="测评记录" name="second">配置管理</el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {useMutitabsStore} from "@/store/mutitabs";
import { apiSystemUserAdd, apiSystemUserEdit, apiSystemRole, apiSystemDept } from "@/api/api";
export default {
  name: "warningDetail",
  setup() {
    const mutitabsstore = useMutitabsStore()
    return {mutitabsstore}
  },
  data() {
    return {
      formData: {},
      activeName: 'first',

    }
  },
  created() { 
    this.formData = this.mutitabsstore.warningDetailitem
 
  },
  methods: {
    imgBeforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJPG) {
        this.$message.error('图片只能是 JPG/PNG 格式!');
        return false
      }
      return isJPG;
    },
    async imgUploadRequest(option) {
      // OSS.ossUploadProductImg(option);
    },
    imgUploadSuccess(res) {
      if (res) {
        this.formData.img = res.url
      }
    },
  }
}
</script>

