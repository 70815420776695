//API DOMAIN   zkxinxin.com 182.44.45.169:8001   http://47.93.151.106/
const API_DOMAIN = process.env.NODE_ENV === 'development' ? "182.44.45.169:8001" : "182.44.45.169:8001"
const APIOTHRER = process.env.NODE_ENV === 'development' ? "zkxinxin.com:8880" : "zkxinxin.com:8880"

//版本号
const APP_VER = require('../../package.json').version
//lyadmin系统配置
module.exports = {
	//APP版本
    APP_VER : APP_VER,

    //API DOMAIN
	API_DOMAIN: API_DOMAIN,
	API_URL_: process.env.NODE_ENV === 'development' ? "http://" + API_DOMAIN  : "http://" + API_DOMAIN ,
	APIOTHER: process.env.NODE_ENV === 'development' ? "http://" + APIOTHRER : "http://" + APIOTHRER,
    //接口地址
	API_URL : process.env.NODE_ENV === 'development' ? "http://"+ API_DOMAIN +"/api/" : "http://"+ API_DOMAIN +"/api/",

    //标题
	APP_TITLE: "心馨图心理健康",

	//是否开启多标签
	ISMULTITABS: true,

	//语言 简体中文 zh-cn、 英文 en（此功能只是示例）
	LANG: 'zh-cn',

	// elementplus 组件大小： small、default、large
	ELEMENT_SIZE: 'default',

	// elementplus 组件 zIndex
	ELEMENT_ZINDEX: 3000,

	// elementplus button组件 autoInsertSpace 是否自动在两个中文字符之间插入空格
	ELEMENT_BUTTON: false,

	//左侧菜单默认宽度 默认 185
	MENU_WIDTH: 185,

	// 左侧菜单和顶部导航颜色 默认 #3C444D
	MENU_HEADER_COLOR:'#3C444D',

	//主题颜色 默认 #6557fa
	COLOR: '#6557fa',

    //默认主题 'dark' 暗黑、'light' 正常
	THEME: 'light',

    //登录信息数据存储方式 localStorage、sessionStorage
	STORAGE_METHOD: 'localStorage',

	//请求超时
	TIMEOUT: 100000000,

}
