<template>
  <div class="lycontainer">
    <div class="lycarousel">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item v-for="item in itemImg" :key="item">
          <img
            class="itemImg"
            :src="require('@/assets/img/' + item + '.png')"
            alt=""
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- <div class="content-search">

    </div> -->
    <div class="leftDiv">
      <headera title="测评单位情况"></headera>
      <div class="content">

      <el-select  v-if="dept_type"  style="margin-bottom: 1vh" filterable clearable @change="deptChange" v-model="deptValue" placeholder="请输入部门名称">
        <el-option
          v-for="item in deptOptions"
          :key="item.dept"
          :label="item.dept_name"
          :value="item.id + '_' + item.dept"
        >
        </el-option>
      </el-select>
        <el-table v-loading="showloading" :data="tableData" style="width: 100%">
          <el-table-column prop="dept_name" label="学校名称"> </el-table-column>
          <el-table-column prop="user_count"  width="70" label="总人数"> </el-table-column>
          <el-table-column prop="name" width="110" label="男生人数/占比">
            <template #default="scope">
              <div>
                {{ scope.row.male_count }}/{{( (scope.row.male_count/scope.row.user_count) * 100).toFixed(1)  }}%
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" width="110" label="女生人数/占比">
             <template #default="scope">
              <div>
                {{ scope.row.female_count }}/{{ (100 - (scope.row.male_count/scope.row.user_count)* 100 ).toFixed(1) }}%
              </div>
            </template>
          </el-table-column> 
        </el-table>
      </div>
    </div>
    <div class="rightDiv">
      <headera title="测评情况"></headera>
      <div >
        <div class="itemnew">
          <div class="itemHeader">累计测评人次 <div class="content-span2">测试覆盖率： {{testviewData.real_users_rate ? (testviewData.real_users_rate * 100).toFixed(1) : ''}}%</div></div>
          <div class="content-span">{{testviewData.real_users_count}}</div>
      
        </div>
      </div>
      <headera title="预警监控情况"></headera>
      <div class="content">
          <el-table :data="yj_viewData" style="width: 100%">
            <el-table-column prop="name" width="150" label="心理健康等级">
            </el-table-column>
            <el-table-column prop="yj_count" label="预警人数"> </el-table-column>
            <el-table-column prop="jk_count" label="监控人数"> </el-table-column>
            <el-table-column prop="rale_count" label="监控比例"> </el-table-column>
          </el-table>
      </div>
    </div>
    <div class="bottomDiv">
      <div class="bottomDiv-1">
        <headera title="心理健康分析"></headera>
        <div class="content">
          <chart1
            className="aasd"
            ref="pieChart"
            height="100%"
            width="100%"
            :options="pieChartOptions"
          />
        </div>
      </div>
      <div class="bottomDiv-2">
        <headera title="年级分布分析"></headera>
        <div class="content">
          <chart2
            className="asssd"
            ref="asssd"
            height="100%"
            width="100%"
            :options="barChartOptions"
          />
        </div>
      </div>
      <div class="bottomDiv-3">
        <headera title="测评维度分析"></headera>
        <div class="content">
          <chart3
            className="asd"
            ref="asd"
            height="100%"
            width="100%"
            :options="duidieBarChartOptions"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style>
</style>
<script>
// deptview
import chart1 from '@/components/Charts/ChartBiao1.vue'
import chart2 from '@/components/Charts/ChartBiao2.vue'
import chart3 from '@/components/Charts/ChartBiao3.vue'
import headera from "./header.vue";
import { deptview,testview,dimview,level_view,yj_view,gradeview,deptviewSearch} from '@/api/api'
export default {
  name: "analysis",
  components: { headera, chart1, chart2, chart3 },
  data() {
    return {
      dept_type:false,
      deptValue: "",
      deptOptions:[],
      itemImg: ['1', '2', '3', '4', '5'],
      testviewData:{},
      tableData: [],
      showloading: true,
      pieChartOptions: {
       color: ['#5A3FFF', '#ADE1FF', '#268AFF', 'red', '#3DFFDC', '#36F097'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          bottom: 20,
          right: 20,
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          top: '10%',
        },
        series: [
          {
            name: '心理健康分析',
            type: 'pie',
            roseType: "radius",
            radius: '70%',
            center: ["50%", "48%"],
            itemStyle: {
              // 需要间距时打开
              // borderColor: '#000',
              // borderWidth: 3,
              shadowColor: 'rgba(0, 0, 0, 0.3)',
              shadowBlur: 20
            },
            data: [
              { value: 648, name: '发展良好' },
              { value: 535, name: '需要关心' },
              { value: 500, name: '重点关怀' },
              { value: 484, name: '亟待关护' }
            ],
            label: {
            show: true,
            formatter(params) {
          
               return params.data.name + '\n' + params.data.value + '人';
            }
         },
            labelLine: {
              show: true,
              lineStyle: {
                color: '#fff',
              },
            },
          }
        ]
      },
      barChartOptions: {
        color: ['#5A3FFF', '#ADE1FF', '#268AFF', '#1ED6FF', '#3DFFDC', '#36F097'],
        legend: {
          show: true,
          icon: 'circle',
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 20,
          textStyle: {
            fontSize: 10,
            color: '#fff',
          },
          data: []
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          containLabel: true,
        },

        tooltip: {
          trigger: "axis",
          show: true,
          formatter: (params) => {
          
            let str = ''
            params.forEach(item => { 
              str += item.seriesName + ':' + item.value + '人\n'
            })
            console.log(str)
              
            return str;
          },
        },
        xAxis: {
          type: 'category',
          data: [],

          axisLabel: {
            color: 'white',
            splitNumber: 0,
            interval: 0,
            // formatter: function (value, index) {
            //   const name = value.split(',')[0]
            //   const title = value.split(',')[1]
            //   return `${title}\n${name}`
            // }
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
        },

        yAxis: {
          type: 'value',
          name: "",
          axisLine: {
            show: false
          },
          nameTextStyle: {
            color: 'white'
          },
          min: 0,
          axisLabel: {
            formatter: "{value}",
            color: 'white'
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              color: 'rgba(255, 255, 255, 0.4)',
              type: 'dashed'
            }
          },
        },
        series: []
      },
      duidieBarChartOptions: {
        color: ['#36F097', '#3DFFDC', '#5A3FFF', '#268AFF', '#1ED6FF', '#ADE1FF','#ebb40f'],
        tooltip: {
          show: true,
          formatter: (params) => {
            return params.name + ":" + params.value + '人';
          },
        },
        series: {
          type: 'sunburst',
          radius: ['20%', '90%'],
          center: ['50%', '50%'],
          sort: null,
          emphasis: {
            focus: 'ancestor',
          },
          data: [ ],
          label: {
            // rotate: 'tangential',
            color: 'black',
            // formatter: (params) => {
            //   if (params.name.length > 3) {
            //     return params.name.substring(0, 3) + '...';
            //   } else {
            //     return params.name;
            //   }
            // },
          },
          levels: [
            {},
            {
              r0: '15%',
              r: '35%',
              label: {
                rotate: 'tangential',
              },
            },
            {
              r0: '35%',
              r: '90%',
              label: {
                align: 'left',
              },
            },
            // {
            //   r0: '60%',
            //   r: '100%',
            //   label: {
            //     position: 'outside',
            //     padding: 10000,
            //     silent: true,
            //   },
            // },
          ],
        },
      },
      yj_viewData: [{
        name: '亟待关注',
        jk_count: '',
        yj_count: '',  
        rale_count: '',
      },{
        name: '重点关怀',
        jk_count: '',
        yj_count: '',  
        rale_count: '',
      },{
        name: '需要关注',
        jk_count: '',
        yj_count: '',  
        rale_count: '',
      },]
    }
  },
  methods: {
    deptChange(value) { 
      let dept = value.split('_')[1]
      // let id = value.split('_')[0]
      let params = {
        page: 1,
        limit: 999,
   
        dept,
      }
      this.getDatas(params)
    },
    // 搜索
    deptviewSearch() { 
      deptviewSearch({
        page: 1,
        limit: 999,
      }).then(res => { 
        console.log(res)
        this.deptOptions = res.data.data
      })
    },
    getDeptType() { 
            // 健康情况
      level_view({
        limit: 999,
        page:1
      }).then(res => { 
        if (!res.data.data.length) return
              // xuqu xian  jidi
  this.dept_type = res.data.data[0].dept_type === 'xuqu' || res.data.data[0].dept_type === 'xian' || res.data.data[0].dept_type === 'jidi' ? true : false
  
      })
  },
    getDatas(params) { 
  
      // 学校情况
      deptview(params).then(res => { 
        if (!res.data.data.length) {
         return this.$message.error('暂无数据') 
        } 
        this.tableData = res.data.data
          this.showloading = false
      })
      //测评情况
      testview(params).then(res => {
          if (!res.data.data.length)  return
        console.log(res.data.data)
        this.testviewData = res.data.data[0]
      })
      // 纬度情况
      dimview(params).then(res => { 
          if (!res.data.data.length)  return
        this.duidieBarChartOptions.series.data = res.data.data[0].dim_content
      })
      // 健康情况
      level_view(params).then(res => { 
        if (!res.data.data.length) return

        this.pieChartOptions.series[0].data =  [
              { value:  res.data.data[0].level_1_count, name: '发展良好' },
              { value:  res.data.data[0].level_2_count, name: '需要关心' },
              { value:  res.data.data[0].level_3_count, name: '重点关怀' },
              { value:  res.data.data[0].level_4_count, name: '亟待关护' }
        ]
      })
      // 学龄分布
      gradeview(params).then(res => {
          if (!res.data.data.length)  return
        this.barChartOptions.legend.data = ['亟待关注','重点关怀','需要关心']
        this.barChartOptions.xAxis.data = []
        this.barChartOptions.series = []
         // ['#5A3FFF', '#ADE1FF', '#268AFF', 'red', '#3DFFDC', '#36F097']
        let color = ['red','#268AFF','#ADE1FF']
        let arr = [] // 存储四个值
        // let level_1_countarr = []
        let level_2_countarr = []
        let level_3_countarr = []
        let level_4_countarr = []
        res.data.data[0].grade_content.data.forEach((i,index) => { 
          this.barChartOptions.xAxis.data.push(i.grade_name)
          // level_1_countarr.push(i.level_1_count)
          level_2_countarr.push(i.level_2_count)
          level_3_countarr.push(i.level_3_count)
          level_4_countarr.push(i.level_4_count)
        })
        arr.push(level_4_countarr,level_3_countarr,level_2_countarr)
             this.barChartOptions.legend.data.forEach((k, kndex) => {
             this.barChartOptions.series.push({
                data: arr[kndex],
                name: k,
                type: 'bar',  
                label: {
                  
                  color: 'black',
                  rotate:270,
                  show: true,
                  formatter: function (value) {
                    return value.data
                  },
                },
                itemStyle: {
                  color: color[kndex]
                }
              })
           })

      })
      // 预警情况
      yj_view(params).then(res => {
          if (!res.data.data.length)  return
        this.yj_viewData[0].jk_count = res.data.data[0].jk4_count
        this.yj_viewData[0].yj_count = res.data.data[0].yj4_count
        this.yj_viewData[0].rale_count = res.data.data[0].yj4_count + res.data.data[0].jk4_count === 0 ? 0 : (res.data.data[0].jk4_count / (res.data.data[0].yj4_count + res.data.data[0].jk4_count)) * 100 + '%'
        this.yj_viewData[1].jk_count = res.data.data[0].jk3_count
        this.yj_viewData[1].yj_count = res.data.data[0].yj3_count
        this.yj_viewData[1].rale_count = res.data.data[0].yj3_count + res.data.data[0].jk3_count === 0 ? 0 : (res.data.data[0].jk3_count / (res.data.data[0].yj3_count + res.data.data[0].jk3_count)) * 100 + '%'
        this.yj_viewData[2].jk_count = res.data.data[0].jk2_count
        this.yj_viewData[2].yj_count = res.data.data[0].yj2_count
        this.yj_viewData[2].rale_count = res.data.data[0].yj2_count + res.data.data[0].jk2_count === 0 ? 0 : (res.data.data[0].jk2_count / (res.data.data[0].yj2_count + res.data.data[0].jk2_count)) * 100 + '%'
      })
    },
  },
  created() {
    setTimeout(() => {
      this.deptviewSearch()
      this.getDatas()
      this.getDeptType()
    }, 600)
  },
}
</script>
<style lang="scss" scoped>
.itemnew {
  .itemHeader{
    display: flex;
    justify-content: space-between;
  }  
    overflow: hidden;
    width: 100%;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 10px 0;
    .content-span{
      padding: 1vh 0;
      color: #FFFFFF;
        background-image: -webkit-linear-gradient(90deg, #a9f7ff, #09f);
       font-family: YouSheBiaoTiHei;
        font-style: normal;
        font-weight: bold;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 33px;
        line-height: 33px;
        display: flex;
        align-items: baseline;
        justify-content: center;
  }
         .content-span2 {
          font-weight: 400;
          font-size: 16px;
          color: #FFDB06;
          line-height: 16px;
          text-align: center;
          font-style: normal;
          text-align: left;
        }

    }

.itemImg {
  width: 20vw;
}
.lycarousel {
  width: 35vw;
  height: 33vh;
  position: absolute;
  left: 30%;
  top: 6%;
}
.icon-border {
  width: 1vh;
  height: 1vh;
  border-radius: 0.5vh;
  margin-right: 1vh;
  // margin: 0.07rem;
  // padding: 0.02rem;
}
::v-deep(.el-table--default) {
  font-size: 0.8rem;
}
::v-deep(.el-carousel__item) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.el-carousel) {
  height: 100%;
}
::v-deep(.el-carousel__container) {
  height: 100%;
}
::v-deep(.el-input__wrapper) {
  background: #0a304f;
  border: 0.01rem solid #0f4572;
  color: #ffffff;
}
::v-deep(.el-select__wrapper) {
  background: #0a304f;
  color: #ffffff;
  border: 0.01rem solid #0f4572;
}
::v-deep(.el-table__cell) {
  background: #054779 !important;
  color: white;
}
::v-deep(.el-select__placeholder){
  color:white !important;
}
::v-deep(.el-select__input-calculator){
  color:white !important;
}
.lr-top {
  display: flex;
  padding: 1vh 0.4rem;
  padding-top: 2vh;
  align-items: center;
  justify-content: center;
}
.lr-img {
  width: 7vh;
  height: 7vh;
  margin-right: 10vh;
}
.index {
  font-size: 4vh;
  font-weight: 700;

  color: #00daf6;
}
.index-title {
  margin-top: 1vh;
  font-size: 2vh;
  color: #7cc6ff;
}
.content-icon {
  display: flex;
  flex-direction: column-reverse;
}
.content-h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  padding: 20px 0;
}
.content-search {
  flex-direction: column;
  position: absolute;
  left: 29%;
  top: 1vh;
  width: 12vw;
  display: flex;
  justify-content: space-between;
}
.content {
  display: flex;
  flex-direction: column;
  overflow: auto;
  background: rgba(0, 55, 98, 0.3);
  padding: 10px;
  height: calc(100% - 32px); //动态计算长度值
}
.leftDiv {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 1vh;
  top: 1vh;
  width: 25vw;
  height: 36vh;
}
.rightDiv {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 1vh;
  top: 1vh;
  width: 25vw;
  height: 37vh;
}
.bottomDiv {
  position: absolute;
  bottom: 1vh;
  right: 1vh;
  width: 99%;
  height: 47vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .bottomDiv-2 {
    display: flex;
    flex-direction: column;
    width: 34vw;
    height: 100%;
  }
  .bottomDiv-1,
  .bottomDiv-3 {
    width: 25vw;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.lycontainer {
  width: 100%;
  height: calc(100vh - 130px); //动态计算长度值
  background: #080a28;
  position: relative;
  /*overflow-x: hidden;*/
  /*overflow-y:auto;*/
}
.echarts-inner {
  margin-top: 1px;
}
::v-deep(.el-scrollbar__bar.is-horizontal) {
  display: none;
}
</style>
