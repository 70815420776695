<template>
  <div class="header-bg">
    {{ title }}
  </div>
</template>
<script>
    export default {
        name: "header",
        props: ['title']
    }
</script>
<style scoped>
.header-bg{
  background-image:url('@/assets/img/header-1.png');
  background-size:100% 100%; 
  font-size: 18px;
  font-weight: 700;
  height: 32px;
    width: 89%;
    padding-left: 10%;
  color: white;

  line-height: 32px;
  
}
</style>
