import html2canvas from "html2canvas";
import jsPDF from "jspdf";

// 判断是否需要添加空白div
const isSplit = (nodes, index, pageHeight) => {
  // 计算当前这块dom是否跨越了a4大小，以此分割
  if (nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight && nodes[index + 1] && nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight) {
    return true;
  }
  return false;
}

const textBreak = (obj) => {
  /* 
    pdf出现分页时图片文字被截断问题
    解决：获取所有的需要下载的外层盒子，循环处理这些盒子，
    获取当前盒子距离顶部的高度offsetTop加上盒子的高度是否大于a4纸的高度，
    如果大于就在之前插入空白盒子，把内容挤下去。
  */
  const A4_WIDTH = 538.58;
  const A4_HEIGHT = 751.18;

  let target = document.getElementById(obj.id);
  let pageHeight = target.scrollWidth / A4_WIDTH * A4_HEIGHT;

  // 先判断有没有之前添加过的emptyDiv 空div，有的话先删除掉
  let emptyDivs = target.getElementsByClassName('emptyDiv')
  if (emptyDivs && emptyDivs.length) {
    for (let i = emptyDivs.length - 1; i >= 0; i--) {
      emptyDivs[i].remove()
    }
  }

  // 获取分割dom，此处为class类名为item的dom
  let lableListID = target.getElementsByClassName(obj.textBreakClassName);
  // 进行分割操作，当dom内容已超出a4的高度，则将该dom前插入一个空dom，把他挤下去，分割
  for (let i = 0; i < lableListID.length; i++) {
    let multiple = Math.ceil((lableListID[i].offsetTop + lableListID[i].offsetHeight) / pageHeight);
    if (isSplit(lableListID, i, multiple * pageHeight)) {
      let divParent = lableListID[i].parentNode; // 获取该div的父节点
      let newNode = document.createElement('div');
      newNode.className = 'emptyDiv';
      newNode.style.background = '#fff';
      let _H = multiple * pageHeight - (lableListID[i].offsetTop + lableListID[i].offsetHeight);
      newNode.style.height = _H + 55 + 'px';
      newNode.style.width = '100%';
      let next = lableListID[i].nextSibling; // 获取div的下一个兄弟节点
      // 判断兄弟节点是否存在
      // console.log(next);
      if (next) {
        // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
        divParent.insertBefore(newNode, next);
      } else {
        // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
        divParent.appendChild(newNode);
      }
    }
  }
}

export const downloadPDF = obj => {
  if (obj.textBreakClassName) {
    textBreak(obj);
  }

  if (obj.loadingId) {
    document.getElementById(obj.loadingId).style.display = 'block';
  }
  let page = document.getElementById(obj.id);
  page.style.overflow = "visible";

  return new Promise((resolve) => {
    html2canvas(page, {
      backgroundColor: "white",
      useCORS: true, // 支持图片跨域
      allowTaint: true, // 是否允许跨域图片
      scale: 1, // 设置放大的倍数
      //dpi: 300,
      dpi: window.devicePixelRatio * 2,
      //height: page.offsetHeight,
      //width: page.offsetWidth, // 为了使横向滚动条的内容全部展示
      windowHeight: document.getElementById(obj.id).scrollHeight, //
      windowWidth: document.getElementById(obj.id).scrollWidth, //为了使横向滚动条的内容全部展示，这里必须指定
    }).then(function (canvas) {
      let result = canvas2PDF(canvas, obj);
      resolve(result);
    });
  })
};

const canvas2PDF = (canvas, obj) => {
  let pdfData = '';
  let pdfPage = 1
  let pdf = new jsPDF('p', 'mm', 'a4');    //A4纸，纵向
  let ctx = canvas.getContext('2d'),
    a4w = 190, a4h = 265,    //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
    imgHeight = Math.floor(a4h * canvas.width / a4w),    //按A4显示比例换算一页图像的像素高度
    renderedHeight = 0;

  while (renderedHeight < canvas.height) {
    let page = document.createElement("canvas");
    page.width = canvas.width;
    page.height = Math.min(imgHeight, canvas.height - renderedHeight);//可能内容不足一页
    //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
    page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0);
    pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 16, a4w, Math.min(a4h, a4w * page.height / page.width));    //添加图像到页面，保留10mm边距
    renderedHeight += imgHeight;
    if (obj.isNeedFooter) {
      pdf.setFontSize(10);// optional
      pdf.text(String(pdfPage), pdf.internal.pageSize.width / 2, 287, {
        align: 'center'
      }); //print number bottom right
    }
    pdfPage++;
    if (renderedHeight < canvas.height)
      pdf.addPage();//如果后面还有内容，添加一个空页
  }
  if (obj.isDownLoad) {
    pdf.save(obj.fileName);
  } else {
    pdfData = pdf.output('datauristring');
  }
  document.getElementById(obj.id).style.overflow = "auto";
  if (obj.loadingId) {
    document.getElementById(obj.loadingId).style.display = 'none';
  }
  return pdfData;
};
