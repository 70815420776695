<template>
  <div>
    <ly-dialog

      v-model="dialogVisible"
      :title="loadingTitle"
      width="660px"
      :before-close="handleClose" 
    >
      <el-form
            v-loading="loading"
        :inline="false"
        :model="formData"
        :rules="rules"
        ref="rulesForm"
        label-position="right"
        label-width="auto"
      >
        <el-form-item label="导入：">
          <el-upload
            class="upload-demo"
            :action="actionUrl"
            limit="1"
            :headers="{
              Authorization: 'JWT ' + token,
            }"
            :file-list="fileList"
            :on-error="imgUploadError"
            :on-success="imgUploadSuccess"
            :before-upload="imgBeforeUpload"
          >
            <el-button type="primary"
              >导入数据<i class="el-icon-upload el-icon--right"></i
            ></el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="模版：">
          <el-button type="text" @click="downloadMoban">下载模版</el-button>
        </el-form-item>
        <!-- <el-form-item label="原因：">
          <div style="font-size: 16px; line-height: 30px; font-weight: 500">
            <span style="color: red">导入的数据失败：</span>
            <span>asdadasd</span>
          </div>
        </el-form-item> -->
      </el-form>
      <!-- <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template> -->
    </ly-dialog>
  </div>
</template>

<script>
import { setStorage, getStorage } from '@/utils/util'
import { iframePdf, UsersUsersAdd, UsersUsersEdit, platformsettingsUploadPlatformImg, apiSystemDept } from "@/api/api";
import LyDialog from "../../../components/dialog/dialog";
import XEUtils from "xe-utils";
export default {
  name: "addteacherquery",
  components: { LyDialog },
  emits: ['refreshData'],
  data() {
    return {
      loading:false,
      token: '', 
      fileList: [],
      actionUrl: iframePdf + '/api/users/importexcel/',
      urlExport: '',
      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
      defaultImg: 'this.src="' + require('../../../assets/img/avatar.jpg') + '"',
      formData: {
      },
      rules: {
        dept: [{ required: true, message: '请输入部门', trigger: 'blur' }],
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        // nickname: [
        //     {required: true, message: '请输入昵称',trigger: 'blur'}
        // ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        is_active: [
          { required: true, message: '请选择是否启用', trigger: 'blur' }
        ]
      },
      rolelist: [],
      options: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      let token = getStorage('logintoken')
      this.token = token
    })
  },
  methods: {
    imgBeforeUpload(e, e1) {
      // console.log(e, e1)
      this.loading = true
    },
    imgUploadError() {
      this.loading = false
      this.$message.warning('数据已传入成功，请等待一段时间。')
      this.dialogVisible = false
     },
    imgUploadSuccess(e) {
      this.loading = false
      if (e.code === 2000) {
        this.$message.success(e.msg)
      } else {
        this.fileList = []
        this.$message.warning(e.msg)

      }
      // 
    },
    downloadMoban() {
      window.open(this.urlExport)
    },
    handleClose() {
      this.dialogVisible = false
      this.loadingSave = false
      this.$emit('refreshData')
    },
    addteacherqueryFn(item, flag) {
      // this.getapiSystemDept()
      this.loading = false
         this.fileList = []
      // console.log(item.item.file_url)
      this.loadingTitle = flag
      this.dialogVisible = true
      this.urlExport = item.item.file_url
    },
    submitData() {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = {
            ...this.formData
          }
          // param.role = param.role?param.role.split(" "):[]
          if (this.formData.id) {
            UsersUsersEdit(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            UsersUsersAdd(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
    },
    // imgBeforeUpload(file) {
    //     const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
    //     if (!isJPG) {
    //         this.$message.error('图片只能是 JPG/PNG 格式!');
    //         return false
    //     }
    //     return isJPG;
    // },
    // async imgUploadRequest(param) {
    //     var vm = this
    //     let obj= await platformsettingsUploadPlatformImg(param)
    //     if(obj.code == 2000) {
    //         let res=''
    //         if (obj.data.data[0].indexOf("://")>=0){
    //             res = obj.data.data[0]

    //         }else{
    //             res = url.split('/api')[0]+obj.data.data[0]
    //         }
    //         vm.formData.avatar = res
    //     } else {
    //         vm.$message.warning(res.msg)
    //     }
    // },
    // imgUploadSuccess() {
    //     this.$refs.uploadDefaultImage.clearFiles()
    // }
  }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  line-height: 128px;
  text-align: center;
}
.avatar {
  width: 128px;
  height: 128px;
  display: block;
}
</style>

