<template>
  <div>
    <ly-dialog
      v-model="dialogVisible"
      :title="loadingTitle"
      width="640px"
      :before-close="handleClose"
    >
      <el-form
        :inline="false"
        :model="formData"
        :rules="rules"
        ref="rulesForm"
        label-position="right"
        label-width="auto"
      >
        <el-form-item label="团体名称：" prop="parent_plan_id">
         
        <el-select
            v-model="formData.parent_plan_id"
            clearable
            filterable
            placeholder="请选择"
            @change="handleSelectChange"
          >
            <el-option
              v-for="item in getGroupList"
              :key="item.id"
              :label="item.plan_name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评测人：" required prop="user">
          <el-select
            v-model="formData.user"
            :loading="loading"
            filterable
            remote
            placeholder="请选择"
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in userlist"
              :key="item.id"
              :label="item.dept_name + ' ' + item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
        <el-button type="primary" @click="submitData" :loading="loadingSave"
          >确定</el-button
        >
      </template>
    </ly-dialog>
  </div>
</template>
<script>
import { singleStpost, singleStput, UsersfrontUsers } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import { deepClone } from "@/utils/util";
export default {
  components: { LyDialog },
  emits: ['refreshData'],
  props:['getGroupList'],
  name: "addStudentsEvaluate",
  data() {
    return {
      userlist: [],
      loading: false,
      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
      parent_plan_idCopy:'',
      formData: {
        "product":'',
        "begin_datetime":'',
        "end_datetime":'',
        "user": "",
        "plan_name":'',
        "parent_plan_id": ""
      },
      rules: {
        parent_plan_id: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        user: [
          { required: true, message: '请选择评测人', trigger: 'blur' }
        ],
        product: [
          { required: true, message: '请选择评测方案', trigger: 'blur' }
        ],
      },
      rolelist: [],
      options: [],
    }
  },
  mounted() {
  },
  methods: {
    handleSelectChange(e) {
      this.parent_plan_idCopy = e.id
      this.formData.product = e.product
      this.formData.begin_datetime = e.begin_datetime
      this.formData.end_datetime = e.end_datetime
      this.formData['plan_name'] = e.plan_name
      console.log(e, 987678)
    },
    remoteMethod(query) {
      if (query !== '') {
        this.getDataUsersUsers(query)
      } else {
        this.userlist = [];
      }
    },
    getDataUsersUsers(name) {
      this.loading = true;
      UsersfrontUsers({ page: 1, limit: 999, name: name }).then(res => {
        if (res.code == 2000) {
          this.loading = false;
          this.userlist = res.data.data
        }
      })
    },
    handleClose() {
      this.dialogVisible = false
      this.loadingSave = false
      this.formData = {

      }
    },
    addGroupFn(item, flag) {
      this.loadingTitle = flag
      this.dialogVisible = true
      this.formData = {
        "product":'',
        "begin_datetime":'',
        "end_datetime":'',
        "user": "",
        "plan_name":'',
        "parent_plan_id": ""
      }
    
      if (item) {
        this.formData = deepClone(item)
      }

      // this.formData.role = item ? item.role : []
    },
    submitData() {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = JSON.parse(JSON.stringify(this.formData))
          param['parent_plan_id'] = this.parent_plan_idCopy
       
          if (this.formData.id) {
            singleStput(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            // console.log(param)
            singleStpost(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
    },



  }
}
</script>

