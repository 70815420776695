<template>
    <div v-dialogDrag>
        <el-dialog
                :title="loadingTitle"
                v-model="dialogVisible"
                width="560px"
                center
                :destroy-on-close="true"
                :close-on-click-modal="false"
                :before-close="handleClose">
 <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
    <el-tab-pane label="基本信息" name="first">
      <el-form :inline="false" :model="formData" ref="rulesForm" label-position="right" label-width="130px">
                <el-form-item label="用户：" prop="name">
                    {{formData.name}}
                </el-form-item>
                <el-form-item label="评估状态：" prop="evaluate_status_label">
                    {{formData.evaluate_status_label}}
                </el-form-item>
                <el-form-item label="预警级别：" prop="warning_level_label">
                    {{formData.warning_level_label}}
                </el-form-item>
                <el-form-item label="预警原因：" prop="description">
                    {{formData.description}}
                </el-form-item>
               
            </el-form>

    </el-tab-pane>
    <!-- <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
    <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
    <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane> -->
  </el-tabs>

      
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
    <!--            <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>-->
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import {apiSystemUserAdd,apiSystemUserEdit,apiSystemRole,apiSystemDept} from "@/api/api";
    export default {
        name: "userDetail",
        emits: ['refreshData'],
        data() {
            return {
                   activeName: 'first',
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                defaultImg:'this.src="'+require('../../../assets/img/avatar.jpg')+'"',
                formData:{
                    name:'',
                    nickname:'',
                    username:'',
                    mobile:'',
                    create_datetime:'',
                    update_datetime:'',
                    is_active:true,
                    avatar:''
                },
                rolelist:[],
                options:[],
            }
        },
        methods:{

            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.$emit('refreshData')
            },
            addUserFn(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                this.formData=item ? item : {
                    name:'',
                    nickname:'',
                    username:'',
                    mobile:'',
                    create_datetime:'',
                    update_datetime:'',
                    is_active:true,
                    avatar:''
                }
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        let param = {
                            ...this.formData
                        }
                        if(this.formData.id){
                            apiSystemUserEdit(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }else{
                            apiSystemUserAdd(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }

                    }
                })
            },
            imgBeforeUpload(file) {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJPG) {
                    this.$message.error('图片只能是 JPG/PNG 格式!');
                    return false
                }
                return isJPG;
            },
            async imgUploadRequest(option) {
                // OSS.ossUploadProductImg(option);
            },
            imgUploadSuccess(res) {
                if (res) {
                    this.formData.img = res.url
                }
            },
        }
    }
</script>

