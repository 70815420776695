<template>
  <div>
    <ly-dialog
      v-model="dialogVisible"
      :title="loadingTitle"
      width="640px"
      :before-close="handleClose"
    >
      <el-form
        :inline="false"
        :model="formData"
        :rules="rules"
        ref="rulesForm"
        label-position="right"
        label-width="auto"
      >
        <!--<el-form-item label="管理员编号：" prop="id">-->
        <!--<el-input v-model.trim="formData.id" style="width: 300px"></el-input>-->
        <!--</el-form-item>-->
        <el-form-item label="任务名称：" prop="plan_name">
          <el-input v-model="formData.plan_name"></el-input>
        </el-form-item>
        <!--<el-form-item label="权限字符：" prop="name">
                    <el-input v-model.trim="formData.name" style="width: 300px"></el-input>
                </el-form-item>-->
        <el-form-item label="任务时间段：" required prop="dateList">
          <el-date-picker
            @change="handlerPicker"
            v-model="formData.dateList"
            type="datetimerange"
            value-format="YYYY-MM-DD HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="测评方案：" required prop="product">
          <el-select v-model="formData.product" filterable placeholder="请选择">
            <el-option
              v-for="item in productsgetlist"
              :key="item.product_id"
              :label="item.product_name"
              :value="item.product_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评测人：" required prop="user">
          <el-select
            v-model="formData.user"
            :loading="loading"
            filterable
            remote
            placeholder="请选择"
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in userlist"
              :key="item.id"
              :label="item.dept_name + ' ' + item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务说明：">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model.trim="formData.description"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
        <el-button type="primary" @click="submitData" :loading="loadingSave"
          >确定</el-button
        >
      </template>
    </ly-dialog>
  </div>
</template>
<!-- export const singleStget = params => ajaxGet({ url: `plan/single/`, params })
export const singleStpost = params => ajaxGet({ url: `plan/single/`, params })
export const singleStput = params => ajaxGet({ url: `plan/single/`, params })
export const singleStdelete = params => ajaxGet({ url: `plan/single/`, params }) -->
<script>
import { singleStpost, singleStput, UsersfrontUsers } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import XEUtils from "xe-utils";
import { deepClone } from "@/utils/util";
export default {
  components: { LyDialog },
  emits: ['refreshData'],
  props: ['productsgetlist'],
  name: "addStudentsEvaluate",
  data() {
    return {
      userlist: [],
      loading: false,
      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
      formData: {
        "product": "",
        dateList: [],
        "user": "",
        "plan_name": "",
        "description": "",
        "begin_datetime": "",
        "end_datetime": ""
      },
      rules: {
        plan_name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        user: [
          { required: true, message: '请选择评测人', trigger: 'blur' }
        ],
        // dept: [
        //     {required: true, message: '请选择部门',trigger: 'blur'}
        // ],
        product: [
          { required: true, message: '请选择评测方案', trigger: 'blur' }
        ],
        dateList: [
          { required: true, message: '请选择评测日期', trigger: 'blur' }
        ]
      },
      rolelist: [],
      options: [],
    }
  },
  methods: {

    remoteMethod(query) {
      if (query !== '') {
        this.getDataUsersUsers(query)
      } else {
        this.userlist = [];
      }
    },
    getDataUsersUsers(name) {
      this.loading = true;
      UsersfrontUsers({ page: 1, limit: 999, name: name }).then(res => {
        if (res.code == 2000) {
          this.loading = false;
          this.userlist = res.data.data
        }
      })
    },
    handlerPicker(e) {
      this.formData.begin_datetime = e[0]
      this.formData.end_datetime = e[1]
    },
    handleClose() {
      this.dialogVisible = false
      this.loadingSave = false
      this.formData = {
        "product": "",
        dateList: [],
        "user": "",
        "plan_name": "",
        "description": "",
        "begin_datetime": "",
        "end_datetime": ""
      }
    },
    addAdminFn(item, flag) {
      this.loadingTitle = flag
      this.dialogVisible = true

      // // console.log(item,'item----')
      // if(item && item.dept) {
      //     item.dept = item.dept.split(" ")
      // }
      if (item) {
        this.formData = deepClone(item)
        this.formData.dateList = [this.formData.begin_datetime, this.formData.end_datetime]
      }

      // this.formData.role = item ? item.role : []
    },
    submitData() {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = JSON.parse(JSON.stringify(this.formData))
          delete param.dateList;
          if (this.formData.id) {
            singleStput(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            // console.log(param)
            singleStpost(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
    },



  }
}
</script>

