<template>
  <div>
    <ly-dialog
      v-model="dialogVisible"
      :title="loadingTitle"
      width="560px"
      :before-close="handleClose"
    >
      <el-form
        :inline="false"
        :model="formData"
        :rules="rules"
        ref="rulesForm"
        label-position="right"
        label-width="auto"
      >
        <el-form-item label="学生姓名：" prop="name">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="出生日期：" prop="birth_date">
          <el-date-picker
            v-model="formData.birth_date"
            type="date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            @change="changeYear"
            placeholder="出生日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="性别：" prop="gender">
          <el-select v-model="formData.gender" placeholder="请选择">
            <el-option label="男" :value="1"> </el-option>
            <el-option label="女" :value="0"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年级：" prop="grade">
            <el-select v-model="formData.grade" @change="changeGrade" clearable placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="班级：" prop="dept">
             <el-select v-model="formData.dept" placeholder="请选择">
                <el-option
                v-for="item in optionsdepts"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input v-model="formData.mobile"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
        <el-button type="primary" @click="submitData" :loading="loadingSave"
          >确定</el-button
        >
      </template>
    </ly-dialog>
  </div>
</template>

<script>
import { UsersfrontAdd, UsersfrontEdit, platformsettingsUploadPlatformImg, apiSystemDept } from "@/api/api";
import LyDialog from "../../../components/dialog/dialog";
import XEUtils from "xe-utils";
export default {
  name: "addUser",
  components: { LyDialog },
  props: ['options'],
  emits: ['refreshData'],
  data() {
    return {
        optionsdepts:[],
      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
      defaultImg: 'this.src="' + require('../../../assets/img/avatar.jpg') + '"',
      formData: {
        gender: '',
        name: '',
        mobile: '',
          grade: '',
        school:'',
        dept: '',
        birth_date: ''
      },
      rules: {
        birth_date: [{ required: true, message: '请输入出生日期', trigger: 'blur' }],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        gender:[
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        grade   : [
          { required: true, message: '请选择年级', trigger: 'change' }
        ],
        dept: [
          { required: true, message: '请输入班级', trigger: 'change' }
        ]
      },
      rolelist: [],
    }
    },
  watch: {
    'formData.grade': {
      handler(val) {
        
          this.changeGradenew(val)
        },
        deep: true
        },
      options: {
        handler(val) {
            console.log(val,123456)
        },
        immediate: true
        }
    },
    mounted() { 
      
    },
  methods: {
        changeGradenew(e) {
            this.optionsdepts = []
              // this.formData.dept = '' // 
            if (e) { 
                this.options.forEach(i => {
                    if (i.id == e) {
                        this.optionsdepts = i.children
                        this.formData.school = i.parent
                    }
                })   
            }
        },
        changeGrade(e) {
            this.optionsdepts = []
              this.formData.dept = '' // 
            if (e) { 
                this.options.forEach(i => {
                    if (i.id == e) {
                        this.optionsdepts = i.children
                        this.formData.school = i.parent
                    }
                })   
            }
        },
    changeYear(e) {
    //     this.formData.birth_date = e
    //   console.log(e)
    },
    handleClose() {
      this.dialogVisible = false
      this.loadingSave = false
      this.$emit('refreshData')
    },
    addUserFn(item, flag) {
      this.loadingTitle = flag
      this.dialogVisible = true
      if (item) {
        delete this.rules.password
        this.formData = item
      } else {
        // this.rules.password = [
        //   { required: true, message: '请输入密码', trigger: 'blur' }
        // ]
        this.formData = {
          gender: '',
        name: '',
        mobile: '',
            grade: '',
        school:'',
        dept: '',
        birth_date: ''
        }
      }
    },
    submitData() {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = {
            ...this.formData
          }
          if (this.formData.id) {
            UsersfrontEdit(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData',res)
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            UsersfrontAdd(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData', res)
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
    },
    imgBeforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJPG) {
        this.$message.error('图片只能是 JPG/PNG 格式!');
        return false
      }
      return isJPG;
    },
    // async imgUploadRequest(param) {
    //     var vm = this
    //     let obj= await platformsettingsUploadPlatformImg(param)
    //     if(obj.code == 2000) {
    //         let res=''
    //         if (obj.data.data[0].indexOf("://")>=0){
    //             res = obj.data.data[0]

    //         }else{
    //             res = url.split('/api')[0]+obj.data.data[0]
    //         }
    //         vm.formData.avatar = res
    //     } else {
    //         vm.$message.warning(res.msg)
    //     }
    // },
    imgUploadSuccess() {
      this.$refs.uploadDefaultImage.clearFiles()
    }
  }
}
</script>
<style scoped>
    ::v-deep(.el-input){
       width: 100%;
    }
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  line-height: 128px;
  text-align: center;
}
.avatar {
  width: 128px;
  height: 128px;
  display: block;
}
</style>

