<template>
  <div>
    <ly-dialog
      v-model="dialogVisible"
      :title="loadingTitle"
      width="640px"
      :before-close="handleClose"
    >
      <el-form
        :inline="false"
        :model="formData"
        :rules="rules"
        ref="rulesForm"
        label-position="right"
        label-width="auto"
      >
      <el-form-item label="用户名称：" required prop="user">
          <el-select v-if="loadingTitle !== '编辑'" filterable   :remote-method="remoteMethod"     remote  :loading="loading"   v-model="formData.user" placeholder="请选择">
            <el-option
        
              v-for="item in userlist"
              :key="item.id"
         :label="item.dept_name + ' ' + item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>  
          <el-input v-else-if="loadingTitle === '编辑'" disabled v-model.trim="formData.name" ></el-input>
        </el-form-item>
        <!--<el-form-item label="管理员编号：" prop="id">-->
        <!--<el-input v-model.trim="formData.id" style="width: 300px"></el-input>-->
        <!--</el-form-item>-->

        <!--<el-form-item label="权限字符：" prop="name">
                    <el-input v-model.trim="formData.name" style="width: 300px"></el-input>
                </el-form-item>-->
        <!-- <el-form-item label="任务时间段：" required prop="dateList">
          <el-date-picker
            @change="handlerPicker"
            v-model="formData.dateList"
            type="datetimerange"
            value-format="YYYY-MM-DD HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item> -->
           <el-form-item label="评估状态：" required prop="evaluate_status">
          <el-select v-model="formData.evaluate_status" placeholder="请选择">
          <el-option
              v-for="item in evaluate_statusList"
              :key="item.key"
         :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预警级别：" required prop="warning_level">
          <el-select v-model="formData.warning_level" placeholder="请选择">
           <el-option
              v-for="(item,index) in warning_levelList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
              :disabled="formData.evaluate_status == 1 && index > 1 && loadingTitle !== '编辑' ? true : false"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="预警原因：">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model.trim="formData.description"
          ></el-input
          >
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
        <el-button type="primary" @click="submitData" :loading="loadingSave"
          >确定</el-button
        >
      </template>
    </ly-dialog>
  </div>
</template>
<script>
import { warningStpost, warningStput,UsersfrontUsers,getfrontUsers } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import XEUtils from "xe-utils";
import { deepClone } from "@/utils/util";
export default {
  components: { LyDialog },
  emits: ['refreshData'],
  props:['warning_levelList','evaluate_statusList'],
  name: "addStudentsEvaluate",
  data() {
    return {
      userlist: [],
            loading: false,
      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
      formData: { 
        "user": "", 
        "description": "", 
        warning_level: '',
        evaluate_status:''
      },
      rules: {
        evaluate_status: [
          { required: true, message: '请选择评估状态', trigger: 'blur' }
        ],
        user: [
          { required: true, message: '请选择用户', trigger: 'blur' }
        ],
        // dept: [
        //     {required: true, message: '请选择部门',trigger: 'blur'}
        // ],
        warning_level: [
          { required: true, message: '请选择预警级别', trigger: 'blur' }
        ]
      },
      rolelist: [],
      options: [],
    }
  },
  watch: {
    // 'formData.user': {
    //   handler(val) {
    //   getfrontUsers({ id: val }).then(res => {
    //     if (res.code == 2000) {
    //       this.loading = false;
    //       this.userlist = res.data.data
    //     }
    //   })
    //   },
    //   deep: true
    // }
  },
  methods: {
      remoteMethod(query) {
      if (query !== '') {
        this.getDataUsersUsers(query)
      } else {
        this.userlist = [];
      }
    },
    getDataUsersUsers(name) {
      this.loading = true;
      UsersfrontUsers({ page: 1, limit: 999, name: name }).then(res => {
        if (res.code == 2000) {
          this.loading = false;
          this.userlist = res.data.data
        }
      })
    },
    handleClose() {
      this.dialogVisible = false
      this.loadingSave = false
      this.formData = {
         "user": "", 
        "description": "", 
        warning_level: '',
        evaluate_status:''
      }
    },
    addAdminFn(item, flag) {
      this.loadingTitle = flag
      this.dialogVisible = true
     
      // // console.log(item,'item----')
      // if(item && item.dept) {
      //     item.dept = item.dept.split(" ")
      // }
      if (item) {
        this.formData = deepClone(item)
        this.formData.warning_level =  this.formData.warning_level + ''
      }

      // this.formData.role = item ? item.role : []
    },
    submitData() {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true         
          let param = JSON.parse(JSON.stringify(this.formData))
      
          if (this.formData.id) {
            warningStput(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            // console.log(param)
            warningStpost(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
    },
  


  }
}
</script>

