<template>
  <div  class="iframeSreportDiv">
    <div style="height: 100%;" >
      <div ref="file"></div>
    </div>
  </div>
</template>
<script>
// 引入axios用来发请求
import axios from "axios";
import { getStore, removeStore } from "@/utils/util";
import { previewWord  } from '@/api/api'
// 引入docx-preview插件 reqExpost
let docx = require("docx-preview");
export default {
  // props: {
  //   // 接收父组件传递的fileUrl
  //   fileUrl: {
  //     type: String,
  //     default: ""
  //   }
  // },
  components: {
  

  },
  setup() {
  },
  name: 'filePreview',
  data() {
    return {
  
    }
  },
  
  mounted() { 
    //  console.log(JSON.stringify(getStore('fileUrl')))
    if (getStore('fileUrl')) { 
  this.goPreview(getStore('fileUrl'))
    
    }
   
  },
  methods: {
   // 预览
    goPreview(fileUrl) {
  
      axios({
        method: "get",
        responseType: "blob", // 因为是流文件，所以要指定blob类型 http://182.44.45.169:8001/media/systemexport/洪志太帅了.docx
        url: fileUrl, // 自己的服务器，提供的一个word下载文件接口
      }).then(({ data }) => {
        console.log(data,123123); // 后端返回的是流文件
        docx.renderAsync(data, this.$refs.file); // 渲染到页面
      });
    },
  },
  created() {
  },


  unmounted() {
    removeStore('fileUrl')
  },
}
</script>
<style>
.iframeSreportDiv{
    width: 100%;
  height: 100%;
}
.btnPdf{
  position: absolute;
  right: 5px;
  top:5px;
}
.iframeDiv{
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
