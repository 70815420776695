<template>
  <div  class="iframeSreportDiv">
     <!-- <div
              class="table-operate-btn btnPdf"
              @click="downPdfFile"
      
              >下载pdf</div
            > -->
    <div style="height: 100%;" >
        <iframe class="iframeDiv"  id="pdfcontents_" :src="formData" frameborder="0" />
    </div>

  </div>
</template>
<script>
import {useMutitabsStore} from "@/store/mutitabs";

import { getStore } from "@/utils/util";
  import { newLodaPDF ,downloadPDF} from "@/utils/pdf.js"
export default {
  components: {

  },
  setup() {
    const mutitabsstore = useMutitabsStore()
    return {mutitabsstore}
  },
  name: 'iframeSreport',
  data() {
    return {
      pdfInfo: {
      id: "autoReportChartPdfToHtmlId",
      fileName: "自动报表.pdf",
      textBreakClassName: "nwsrBreakClass",
          },
      formData: null,
      base64PdfData:{}
    }
  },
  methods: {
       downPdfFile() {
    
        this.$nextTick(async () => {
          this.base64PdfData = await downloadPDF({
            id: 'pdfcontents_',
            fileName: '报告',
            textBreakClassName: this.pdfInfo.textBreakClassName,
            isDownLoad: true,
            isNeedFooter: true,
          });

        })
      }
  },
  created() {
  },
  mounted() {
      // eslint-disable-next-line no-global-assign
    this.$nextTick(() => { 
      this.formData = getStore('urlifarme')
    })
    //  this.formData = this.mutitabsstore.warningDetailitem
  },

  unmounted() {
  },
}
</script>
<style>
.iframeSreportDiv{
    width: 100%;
  height: 100%;
}
.btnPdf{
  position: absolute;
  right: 5px;
  top:5px;
}
.iframeDiv{
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
